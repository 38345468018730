/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-16 15:56:12
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/classicalPrescription'

// 经典方剂列表
export const getClassicalPrescriptionList = createAsyncThunk<
  void,
  DT.ClassicalPrescriptionListPrams,
  RootThunkApi<void>
>('classicalPrescription/getHealthList', async (params, api) => {
  return sendAsync(DT.classicalPrescriptionList(params), api)
})
 
// 上架
export const getClassicalPrescriptionGrounding = createAsyncThunk<
  void,
  DT.GroundingParams,
  RootThunkApi<void>
>(
  'classicalPrescription/getClassicalPrescriptionGrounding',
  async (params, api) => {
    return sendAsync(DT.classicalPrescriptionGrounding(params), api)
  }
)

// 下架
export const getClassicalPrescriptionUndercarriage = createAsyncThunk<
  void,
  DT.UndercarriageParams,
  RootThunkApi<void>
>(
  'classicalPrescription/getClassicalPrescriptionUndercarriage',
  async (params, api) => {
    return sendAsync(DT.classicalPrescriptionUndercarriage(params), api)
  }
)

// 详情
export const getClassicalPrescriptionDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>(
  'classicalPrescription/geClassicalPrescriptionDetail',
  async (params, api) => {
    return sendAsync(DT.ClassicalPrescriptionDetail(params), api)
  }
)

// 删除
export const getDeleteSubmit = createAsyncThunk<
  void,
  DT.deleteParams,
  RootThunkApi<void>
>('classicalPrescription/getDeleteSubmit', async (params, api) => {
  return sendAsync(DT.deleteSubmit(params), api)
})

// 保存
export const getAddOrEditClassicalPrescriptionSubmit = createAsyncThunk<
  void,
  DT.AddOrEditClassicalPrescriptionPrams,
  RootThunkApi<void>
>(
  'classicalPrescription/getAddOrEditClassicalPrescriptionSubmit',
  async (params, api) => {
    return sendAsync(DT.addOrEditClassicalPrescriptionSubmit(params), api)
  }
)

// 方剂类型列表
export const getPrescriptionTypeListAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('classicalPrescription/getPrescriptionTypeListAsync', async (params, api) => {
  return sendAsync(DT.prescriptionTypeList(params), api)
})

// 方剂类型保存
export const getPrescriptionTypeAddOrEditSubmit = createAsyncThunk<
  void,
  DT.prescriptionTypeAddOrEditPrams,
  RootThunkApi<void>
>(
  'classicalPrescription/getPrescriptionTypeAddOrEditSubmit',
  async (params, api) => {
    return sendAsync(DT.prescriptionTypeAddOrEditSubmit(params), api)
  }
)

// 方剂类型详情
export const getPrescriptionTypeDetail = createAsyncThunk<
  void,
  DT.prescriptionTypeDetailParams,
  RootThunkApi<void>
>('classicalPrescription/getPrescriptionTypeDetail', async (params, api) => {
  return sendAsync(DT.prescriptionTypeDetail(params), api)
})

// 方剂类型删除
export const getPrescriptionTypeDelete = createAsyncThunk<
  void,
  DT.prescriptionTypeDeleteParams,
  RootThunkApi<void>
>('classicalPrescription/getPrescriptionTypeDelete', async (params, api) => {
  return sendAsync(DT.prescriptionTypeDelete(params), api)
})

// oss图片上传
export const getClassicalPrescriptionOssUrl = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>(
  'classicalPrescription/getClassicalPrescriptionOssUrl',
  async (params, api) => {
    return sendAsync(DT.classicalPrescriptionOssUrl(params), api)
  }
)

// 预诊问题列表
export const getQuestionListAsync = createAsyncThunk<
  void,
  DT.QuestionListPrams,
  RootThunkApi<void>
>('classicalPrescription/getQuestionListAsync', async (params, api) => {
  return sendAsync(DT.questionList(params), api)
})

// 预诊问题详情
export const getQuestionDetailAsync = createAsyncThunk<
  void,
  DT.QuestionDetailPrams,
  RootThunkApi<void>
>('classicalPrescription/getQuestionDetailAsync', async (params, api) => {
  return sendAsync(DT.questionDetail(params), api)
})

// 预诊问题编辑保存
export const getQuestionEditSubmitAsync = createAsyncThunk<
  void,
  DT.QuestionEditSubmitPrams,
  RootThunkApi<void>
>('classicalPrescription/getQuestionEditSubmitAsync', async (params, api) => {
  return sendAsync(DT.questionEditSubmitAsync(params), api)
})

// 预诊问题删除
export const getQuestionDeleteAsync = createAsyncThunk<
  void,
  DT.questionDeletePrams,
  RootThunkApi<void>
>('classicalPrescription/getQuestionDeleteAsync', async (params, api) => {
  return sendAsync(DT.questionDelete(params), api)
})

//  药品详情
export const getListByComboIdAsync = createAsyncThunk<
  void,
  DT.listByComboIdParams,
  RootThunkApi<void>
>('classicalPrescription/getListByComboIdAsync', async (params, api) => {
  return sendAsync(DT.listByComboId(params), api)
})

//药品外层
export const getFormulaDetailListAsync = createAsyncThunk<
  void,
  DT.formulaDetailListParams,
  RootThunkApi<void>
>('classicalPrescription/getFormulaDetailListAsync', async (params, api) => {
  return sendAsync(DT.formulaDetailList(params), api)
})

// 医生列表
export const getDoctorListAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('classicalPrescription/getFormulaDetailListAsync', async (params, api) => {
  return sendAsync(DT.docList(), api)
})

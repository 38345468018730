import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { DateTimeFormatMessageSimple } from '../../models/datetime'
import {
  getReleaseStateName,
  ReleaseStateCategories,
} from '../../models/onLineAuctionParty'
import {
  getOrderStateName,
  ReleaseOrderStateCategories,
} from '../../models/orderManagemnet'
import { getDoctorListAsync } from '../classicalPrescription/classicalPrescriptionSlice'
import { getDepartmentManagementList } from '../DepartmentManagement/departmentManagementSlice'
import { getDepartmentListData } from './onlinePrescriptionSlice'

interface OnLinePrescriptionListPrams {
  current: number
  size: number
  name?: any
  departmentId?: any
  doctorId?: any
  applicationEndTime?: any
  applicationStartTime?: any
  prescribedEndTime?: any
  prescribedStartTime?: any
}

export const Query = (props: {
  onValueChange: (queries: OnLinePrescriptionListPrams) => void
  allUpload: () => void
  buttonLoading?: any
}): ReactElement => {
  const { Option } = Select

  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [departments, setDepartments] = useState<any[]>([])

  const [doctorList, setDoctorList] = useState<any>([]) //医生列表

  useEffect(() => {
    getDepartmentList()
    getDoctorList()
  }, [dispatch])

  const getDepartmentList = () => {
    dispatch(getDepartmentListData())
      .then(unwrapResult)
      .then((ds: any) => setDepartments(ds))
      .catch(() => {
        // do nothing
      })
  }

  const getDoctorList = () => {
    dispatch(getDoctorListAsync())
      .then(unwrapResult)
      .then((v: any) => {
        setDoctorList(v)
      })
  }
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => {
          console.log(vs, 'vsvsvsvsvs')
          props.onValueChange({
            ...vs,
            prescribedStartTime: vs?.prescribedTimeHead
              ? moment(vs?.prescribedTimeHead).format(
                  DateTimeFormatMessageSimple
                )
              : head,
            prescribedEndTime: vs?.prescribedTimeTail
              ? moment(vs?.prescribedTimeTail).format(
                  DateTimeFormatMessageSimple
                )
              : tail,
            prescribedTimeHead: undefined,
            prescribedTimeTail: undefined,
          })
        }}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='就诊人姓名/手机号'
                style={{ width: 200 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='departmentId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='科室'
                allowClear
                onChange={form.submit}
              >
                {departments?.map((d) => (
                  <Select.Option
                    key={d.id}
                    value={d.id}
                  >
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='doctorId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='医生'
                allowClear
                onChange={form.submit}
              >
                {doctorList?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.realname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              allowClear={false}
              placeholder='开方时间'
              labelPrefix='开方时间-'
              namePrefix='prescribedTime'
              initialValue={DateRangeType.ThisMonth}
              style={{ width: '8.5rem' }}
              onChange={form.submit}
            />
            <Form.Item noStyle name='state'>
              <Select
                style={{ width: '10rem' }}
                placeholder='结算状态'
                allowClear
                onChange={form.submit}
              >
                {ReleaseOrderStateCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getOrderStateName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='recipeUploadStatus'>
              <Select
                style={{ width: '10rem' }}
                placeholder='上传状态'
                allowClear
                onChange={form.submit}
              >
                <Select.Option key={0} value={0}>
                  待上传
                </Select.Option>
                <Select.Option key={1} value={1}>
                  已上传
                </Select.Option>
              </Select>
            </Form.Item>
            <Button type='primary' onClick={() => form.submit()}>
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              loading={props?.buttonLoading}
              onClick={() => {
                props?.allUpload()
              }}
            >
              批量上传
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

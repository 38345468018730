/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-15 09:22:26
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-13 16:16:14
 */
import { CaretUpFilled } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Radio,
  Row,
  Space,
  Table,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { DateTimeFormatSimpleOnly } from '../../models/datetime'
import { getRecipeCategoryName } from '../../models/recipe'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { getDetailAsync } from '../onLineAuctionParty/onLineAuctionPartySlice'
import styles from './onlinePrescription.module.css'

interface goToAuditModalProps {
  recipeId?: any
  treatmentId?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const OnlinePrescriptionDetailModal = ({
  recipeId,
  treatmentId,
  visible,
  onCancel,
  onOk,
}: goToAuditModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const { Panel } = Collapse

  const [detail, setDetail] = useState<any>()

  useEffect(() => {
    if (visible) {
      dispatch(getDetailAsync({ recipeId: recipeId, treatmentId: treatmentId }))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [visible])

  return (
    <>
      <Modal
        title={'详情'}
        width={800}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        className={styles.onlinePrescriptionDetailModal}
        centered
        footer={null}
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Col className={styles.modal}>
          <Row className={styles.mb_10}>
            <Col span={8}>姓名：{detail?.name || '-'}</Col>
            <Col span={8}>性别：{getGenderName(detail?.sex)}</Col>
            <Col span={8}>年龄：{detail?.age}岁</Col>
          </Row>
          <Row className={styles.mb_10}>
            <Col span={8}>手机号：{detail?.phone}</Col>
            <Col span={8}>身份证号：{detail?.idCard}</Col>
          </Row>

          {detail?.recipes?.map((v: any) => {
            return (
              <>
                <div className={styles.tableContent}>
                  <Row>
                    <span
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      诊断: {v?.diagnosis}
                    </span>
                    <span>
                      开方日期:{' '}
                      {moment(v?.prescribedTime).format(
                        DateTimeFormatSimpleOnly
                      )}
                    </span>
                  </Row>
                  <Row className={styles.mb_10} style={{ margin: '10px 0' }}>
                    <span>{getRecipeCategoryName(v?.recipeType)}</span>
                    {v?.recipeType == 3 ? (
                      <>
                        <span> , 帖数:{v?.tip}帖</span>
                        <span>（中药饮片方）,</span>
                        <span>用法: {v?.useMethod || '-'}</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Table
                    pagination={false}
                    size='small'
                    bordered
                    dataSource={v?.details}
                    rowKey={(t: any) => t.id}
                    columns={
                      v?.recipeType == 3
                        ? [
                            {
                              title: '序号',
                              key: 'order',
                              align: 'center',
                              render: (_1: any, _2: any, index: any) =>
                                (index + 1).toString(),
                            },
                            {
                              title: '药品名称',
                              dataIndex: 'name',
                              align: 'center',
                              key: 'name',
                            },
                            {
                              title: '每帖克数',
                              dataIndex: 'drugTipCount',
                              align: 'center',
                              key: 'drugTipCount',
                            },
                            {
                              title: '总数量',
                              dataIndex: 'drugCount',
                              align: 'center',
                              key: 'drugCount',
                            },
                            {
                              title: '用法',
                              dataIndex: 'normalUsemethod',
                              align: 'center',
                              key: 'normalUsemethod',
                            },
                          ]
                        : [
                            {
                              title: '序号',
                              key: 'order',
                              align: 'center',
                              width: '3rem',
                              render: (_1: any, _2: any, index: any) =>
                                (index + 1).toString(),
                            },
                            {
                              title: '药品名称',
                              dataIndex: 'name',
                              align: 'center',
                              key: 'name',
                              width: '12rem',
                              ellipsis: true,
                              render: function Element(_, record) {
                                return (
                                  <Popover
                                    content={
                                      <div>
                                        <p
                                          style={{
                                            height: '28px',
                                            lineHeight: '36px',
                                            padding: '0',
                                            margin: '0',
                                            fontSize: '16px',
                                          }}
                                        >
                                          {record.name}
                                        </p>
                                        <p
                                          style={{
                                            lineHeight: '24px',
                                            padding: '0',
                                            margin: '0',
                                            color: '#999999',
                                            fontSize: '14px',
                                          }}
                                        >
                                          {record.drugInfo}
                                        </p>
                                      </div>
                                    }
                                    placement='bottom'
                                  >
                                    <div>
                                      <p
                                        style={{
                                          height: '28px',
                                          lineHeight: '36px',
                                          padding: '0',
                                          margin: '0',
                                          fontSize: '16px',
                                        }}
                                      >
                                        {record.name}
                                      </p>
                                      <p
                                        style={{
                                          lineHeight: '24px',
                                          padding: '0',
                                          margin: '0',
                                          color: '#999999',
                                          fontSize: '14px',
                                        }}
                                      >
                                        {record.drugInfo}
                                      </p>
                                    </div>
                                  </Popover>
                                )
                              },
                            },
                            {
                              title: '用法',
                              dataIndex: 'normalUsemethod',
                              align: 'center',
                              key: 'normalUsemethod',
                              width: '4rem',
                              ellipsis: true,
                            },
                            {
                              title: '频次',
                              dataIndex: 'normalFrequency',
                              align: 'center',
                              key: 'normalFrequency',
                              width: '6rem',
                              ellipsis: true,
                            },
                            {
                              title: '每次用量',
                              dataIndex: 'normalDose',
                              align: 'center',
                              key: 'normalDose',
                              width: '4rem',
                              ellipsis: true,
                            },
                            {
                              title: '天数',
                              dataIndex: 'useDays',
                              align: 'center',
                              key: 'useDays',
                              width: '4rem',
                              ellipsis: true,
                            },
                            {
                              title: '数量',
                              dataIndex: 'drugCount',
                              align: 'center',
                              key: 'drugCount',
                              width: '4rem',
                              ellipsis: true,
                            },
                          ]
                    }
                  ></Table>
                </div>
              </>
            )
          })}
        </Col>
      </Modal>
    </>
  )
}

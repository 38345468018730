/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-15 11:44:01
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-14 14:10:41
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-15 09:22:26
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-15 10:26:29
 */
import { CaretUpFilled } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  Row,
  Image,
  Table,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { DateTimeFormatSimpleOnly } from '../../models/datetime'
import { getRecipeCategoryName } from '../../models/recipe'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import styles from './onLineAuctionParty.module.css'
import { getDetailAsync } from './onLineAuctionPartySlice'

interface detailModalProps {
  recipeState?: any
  treatmentId?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const DetailModal = ({
  recipeState,
  treatmentId,
  visible,
  onCancel,
  onOk,
}: detailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const { Panel } = Collapse

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [detail, setDetail] = useState<any>()

  const labelStyle = {
    style: {
      textAlign: 'left',
      marginBottom: 10,
      marginRight: '6px',
      // width: '90px',
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      marginBottom: 10,
    } as React.CSSProperties,
  }
  const list: any = []
  useEffect(() => {
    if (visible) {
      dispatch(getDetailAsync({ treatmentId: treatmentId }))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [visible])

  return (
    <>
      <Modal
        className={styles.detailModal}
        title={'详情'}
        width={800}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        footer={null}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Row>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>姓名：</Col>
              <Col {...valueStyle}>{detail?.name || '-'}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>性别：</Col>
              <Col {...valueStyle}>{getGenderName(detail?.sex)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>年龄：</Col>
              <Col {...valueStyle}>{detail?.age}岁</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>手机号：</Col>
              <Col {...valueStyle}>{detail?.phone}</Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row>
              <Col {...labelStyle}>身份证号：</Col>
              <Col {...valueStyle}>{detail?.idCard}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>处方：</Col>
              <Col {...valueStyle}>
                {detail?.recipePictures ? (
                  detail?.recipePictures?.split(',')?.map((v: any) => {
                    return (
                      <Image
                        src={v}
                        key={v}
                        style={{
                          width: '60px',
                          height: '60px',
                          marginRight: '10px',
                        }}
                      ></Image>
                    )
                  })
                ) : (
                  <>-</>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>剂型：</Col>
              <Col {...valueStyle}>{detail?.dosageFormk || '-'}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>备注：</Col>
              <Col style={{ flex: '1', marginBottom: '10px' }}>
                {detail?.remark || '-'}
              </Col>
            </Row>
          </Col>
        </Row>
        {recipeState == 3 ? (
          <Row>
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>拒绝原因：</Col>
                <Col style={{ flex: '1', marginBottom: '10px' }}>
                  {detail?.refuseCause || '-'}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {recipeState != 3 ? (
          <Row>
            <Col span={24}>
              {detail?.recipes &&
                detail?.recipes?.map((n: any, index: any) => (
                  <>
                    <Collapse
                      collapsible='header'
                      expandIcon={({ isActive }) => (
                        <CaretUpFilled
                          rotate={isActive ? 0 : 180}
                          style={{ marginBottom: '10px', color: '#666666' }}
                        />
                      )}
                      expandIconPosition='right'
                    >
                      <Panel
                        style={{ marginBottom: '10px' }}
                        key={index}
                        header={
                          <Row
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              height: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                诊断: {n?.diagnosis}
                              </span>
                              <span>
                                开方日期:{' '}
                                {moment(n?.prescribedTime).format(
                                  DateTimeFormatSimpleOnly
                                )}
                              </span>
                            </div>
                          </Row>
                        }
                      >
                        <div className={styles.tableMain}>
                          <div className={styles.tableMainTitle}>
                            <span>{getRecipeCategoryName(n?.recipeType)}</span>
                            {n?.recipeType == 3 ? (
                              <>
                                <span> , 帖数:{n?.tip}帖</span>
                                <span>（中药饮片方）,</span>
                                <span>用法: {n?.useMethod || '-'}</span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <Table
                            pagination={false}
                            size='small'
                            bordered
                            dataSource={n?.details}
                            rowKey={(t: any) => t.id}
                            columns={
                              n?.recipeType == 3
                                ? [
                                    {
                                      title: '序号',
                                      key: 'order',
                                      align: 'center',
                                      render: (_1: any, _2: any, index: any) =>
                                        (index + 1).toString(),
                                    },
                                    {
                                      title: '药品名称',
                                      dataIndex: 'name',
                                      align: 'center',
                                      key: 'name',
                                    },
                                    {
                                      title: '每帖克数',
                                      dataIndex: 'drugTipCount',
                                      align: 'center',
                                      key: 'drugTipCount',
                                    },
                                    {
                                      title: '总数量',
                                      dataIndex: 'drugCount',
                                      align: 'center',
                                      key: 'drugCount',
                                    },
                                    {
                                      title: '用法',
                                      dataIndex: 'normalUsemethod',
                                      align: 'center',
                                      key: 'normalUsemethod',
                                    },
                                  ]
                                :  [
                                  {
                                    title: '序号',
                                    key: 'order',
                                    align: 'center',
                                    width: '3rem',
                                    render: (_1: any, _2: any, index: any) =>
                                      (index + 1).toString(),
                                  },
                                  {
                                    title: '药品名称',
                                    dataIndex: 'name',
                                    align: 'center',
                                    key: 'name',
                                    width: '12rem',
                                    ellipsis: true,
                                    render: function Element(_, record) {
                                      return (
                                        <Popover
                                        content={<div>
                                          <p
                                            style={{
                                              height: '28px',
                                              lineHeight: '36px',
                                              padding: '0',
                                              margin: '0',
                                              fontSize: '16px',
                                            }}
                                          >
                                            {record.name}
                                          </p>
                                          <p
                                            style={{
                                              lineHeight: '24px',
                                              padding: '0',
                                              margin: '0',
                                              color: '#999999',
                                              fontSize: '14px',
                                            }}
                                          >
                                            {record.drugInfo}
                                          </p>
                                        </div>}
                                        placement='bottom'
                                      >
                                        <div>
                                          <p
                                            style={{
                                              height: '28px',
                                              lineHeight: '36px',
                                              padding: '0',
                                              margin: '0',
                                              fontSize: '16px',
                                            }}
                                          >
                                            {record.name}
                                          </p>
                                          <p
                                            style={{
                                              lineHeight: '24px',
                                              padding: '0',
                                              margin: '0',
                                              color: '#999999',
                                              fontSize: '14px',
                                            }}
                                          >
                                            {record.drugInfo}
                                          </p>
                                        </div>
                                      </Popover>
                                      
                                      )
                                    },
                                  },
                                  {
                                    title: '用法',
                                    dataIndex: 'normalUsemethod',
                                    align: 'center',
                                    key: 'normalUsemethod',
                                    width: '4rem',
                                    ellipsis: true,
                                  },
                                  {
                                    title: '频次',
                                    dataIndex: 'normalFrequency',
                                    align: 'center',
                                    key: 'normalFrequency',
                                    width: '6rem',
                                    ellipsis: true,
                                  },
                                  {
                                    title: '每次用量',
                                    dataIndex: 'normalDose',
                                    align: 'center',
                                    key: 'normalDose',
                                    width: '4rem',
                                    ellipsis: true,
                                  },
                                  {
                                    title: '天数',
                                    dataIndex: 'useDays',
                                    align: 'center',
                                    key: 'useDays',
                                    width: '4rem',
                                    ellipsis: true,
                                  },
                                  {
                                    title: '数量',
                                    dataIndex: 'drugCount',
                                    align: 'center',
                                    key: 'drugCount',
                                    width: '4rem',
                                    ellipsis: true,
                                  },
                                ]
                            }
                          ></Table>
                        </div>
                      </Panel>
                    </Collapse>{' '}
                  </>
                ))}
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Modal>
    </>
  )
}

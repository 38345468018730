/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 16:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-19 18:30:39
 */
import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  getReleaseStateName,
  getStatusName,
  ZXFZReleaseStatusCategories,
  ReleaseStatusCategories,
} from '../../models/onLineAuctionParty'
import { RootDispatch } from '../../app/store'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { DateTimeFormatMessageSimple } from '../../models/datetime'
import moment from 'moment'
import { getDepartmentManagementList } from '../DepartmentManagement/departmentManagementSlice'
import { getTenantDoctorsAsync } from '../onlineConsultant/onlineConsultantSlice'

export const Query = (props: {
  onValueChange: (queries: any) => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { Option } = Select

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [departments, setDepartments] = useState<any[]>([])

  const [doctorList, setDoctorList] = useState<any>([]) //医生列表

  useEffect(() => {
    getDepartmentList()
    getDoctorList()
  }, [dispatch])

  const getDepartmentList = () => {
    dispatch(getDepartmentManagementList({ current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((ds: any) => setDepartments(ds?.records))
      .catch(() => {
        // do nothing
      })
  }

  const getDoctorList = () => {
    dispatch(getTenantDoctorsAsync())
      .then(unwrapResult)
      .then((v: any) => {
        setDoctorList(v)
      })
  }

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => {
          props.onValueChange({
            ...vs,
            consultationStartTime: vs?.consultationTimeHead
              ? moment(vs?.consultationTimeHead).format(
                  DateTimeFormatMessageSimple
                )
              : undefined,
            consultationEndTime: vs?.consultationTimeTail
              ? moment(vs?.consultationTimeTail).format(
                  DateTimeFormatMessageSimple
                )
              : undefined,
            startTime: vs?.appointmentTimeHead
              ? moment(vs?.appointmentTimeHead).format(
                  DateTimeFormatMessageSimple
                )
              : undefined,
            endTime: vs?.appointmentTimeTail
              ? moment(vs?.appointmentTimeTail).format(
                  DateTimeFormatMessageSimple
                )
              : undefined,
            consultationTimeHead: undefined,
            consultationTimeTail: undefined,
            appointmentTimeHead: undefined,
            appointmentTimeTail: undefined,
          })
        }}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='search' noStyle>
              <Input
                autoComplete='off'
                placeholder='就诊人姓名/手机号'
                style={{ width: 180 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='departmentId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='科室'
                allowClear
                onChange={form.submit}
              >
                {departments?.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.hisDepartmentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='doctorId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='医生'
                allowClear
                onChange={form.submit}
              >
                {doctorList?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='status'>
              <Select
                placeholder='状态'
                allowClear
                style={{ width: '8rem' }}
                onChange={() => form.submit()}
              >
                {ZXFZReleaseStatusCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getStatusName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='接诊时间'
              labelPrefix='接诊时间-'
              namePrefix='consultationTime'
              style={{ width: '9rem' }}
              onChange={form.submit}
              onlyTime='1'
            />
            <DateSelect
              placeholder='预约时间'
              labelPrefix='预约时间-'
              namePrefix='appointmentTime'
              style={{ width: '9rem' }}
              onChange={form.submit}
              onlyTime='2'
            />

            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Popover, Row, Table } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns, XuFangColumns, BJPYColumns } from './column'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import styles from './orderManagemnet.module.css'
import {
  getOrderManagementDetail,
  getOrderManagementList,
} from './orderManagemnetSlice'
import { getGenderName } from '../../models/user'
import {
  getOrderStateName,
  getReleaseStateName,
} from '../../models/orderManagemnet'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../models/datetime'
import { getRecipeCategoryName } from '../../models/recipe'

export interface OrderRef {
  refresh?: () => void
  tabKey?: any
}

interface OrderProps {
  tabKey?: any
}
const OrderManagement = (
  { tabKey }: OrderProps,
  ref: React.Ref<OrderRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const history: any = useHistory()

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [visible, setVisible] = useState(false)

  const [orderId, setOrderId] = useState<any>('')

  const [detail, setDetail] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  useEffect(() => {
    if (params) {
      getPage()
    }
  }, [params])

  const getPage = () => {
    setPageLoading(true)
    dispatch(getOrderManagementList(params))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (visible) {
      dispatch(getOrderManagementDetail({ orderId: orderId }))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
        })
    } else {
      setOrderId('')
      setDetail(undefined)
    }
  }, [visible])

  return (
    <>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='订单管理'>
          <Col
            style={{
              height: 'calc(100% - 30px)',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              margin: ' 10px 0',
              flexDirection: 'column',
            }}
          >
            <Col
              style={{
                flex: 1,
                backgroundColor: theme.pn,
                borderRadius: 10,
                padding: '15px  15px 0 15px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Query
                onValueChange={(v: any) => {
                  setParams({
                    ...params,
                    current: 1,
                    ...v,
                    pushTimeStart: v?.pushTimeHead,
                    pushTimeEnd: v?.pushTimeTail,
                    pushTimeHead: undefined,
                    pushTimeTail: undefined,
                  })
                }}
              />
              <EditableList
                rowKey={(_, i) => `${i}}`}
                page={{
                  items: page.items,
                  current: params.current,
                  size: params.size,
                  total: page.total,
                }}
                loading={pageLoading}
                columns={columns((action: any, v: any) => {
                  switch (action) {
                    case 'detail':
                      setVisible(true)
                      setOrderId(v?.orderId)
                      break
                  }
                }, params.size * (params.current - 1))}
                onChangePage={(current, size) => {
                  setParams({ ...params, current, size: size || 10 })
                }}
              />
            </Col>
          </Col>
        </TabPane>
      </TabBar>

      <Modal
        title={'订单详情'}
        className={styles.orderDetailMain}
        width={700}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Col className={styles.modal}>
          <Row className={styles.mb_10}>
            <Col span={8}>姓名：{detail?.name}</Col>
            <Col span={8}>性别：{getGenderName(detail?.sex)}</Col>
            <Col span={8}>年龄：{detail?.age}岁</Col>
          </Row>
          <Row className={styles.mb_10}>
            <Col span={8}>手机号：{detail?.phone || '-'}</Col>
            <Col span={8}>身份证号：{detail?.idCard || '-'}</Col>
          </Row>
          <Row className={styles.mb_10}>
            <Col span={8}>
              类型：{getReleaseStateName(detail?.orderType?.toString())}
            </Col>
            <Col span={8}>
              明细：
              {detail?.comment || '-'}
            </Col>
            <Col span={8}>
              订单金额：
              <span className={styles.colorff}>
                ￥{detail?.amount?.toFixed(2) || '-'}
              </span>
            </Col>
          </Row>
          {detail?.recipes?.map((v: any) => {
            return (
              <>
                <div className={styles.tableContent}>
                  <Row>
                    <span
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      诊断 : {v?.diagnosis}
                    </span>
                    <span>
                      开方日期 :{' '}
                      {moment(v?.prescribedTime).format(
                        DateTimeFormatSimpleOnly
                      )}
                    </span>
                  </Row>
                  <Row className={styles.mb_10} style={{ margin: '10px 0' }}>
                    <span>{getRecipeCategoryName(v?.recipeType)} , </span>
                    {v?.recipeType == 3 ? (
                      <>
                        <span>帖数:{v?.tip}帖</span>
                        <span>（中药饮片方）,</span>
                        <span>用法: {v?.useMethod ||'-' }</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Table
                    pagination={false}
                    size='small'
                    bordered
                    dataSource={v?.details}
                    rowKey={(t: any) => t.id}
                    columns={
                      v?.recipeType == 3
                        ? [
                            {
                              title: '序号',
                              key: 'order',
                              align: 'center',
                              render: (_1: any, _2: any, index: any) =>
                                (index + 1).toString(),
                            },
                            {
                              title: '药品名称',
                              dataIndex: 'name',
                              align: 'center',
                              key: 'name',
                            },
                            {
                              title: '每帖克数',
                              dataIndex: 'drugTipCount',
                              align: 'center',
                              key: 'drugTipCount',
                            },
                            {
                              title: '总数量',
                              dataIndex: 'drugCount',
                              align: 'center',
                              key: 'drugCount',
                            },
                            {
                              title: '用法',
                              dataIndex: 'normalUsemethod',
                              align: 'center',
                              key: 'normalUsemethod',
                            },
                          ]
                        : [
                          {
                            title: '序号',
                            key: 'order',
                            align: 'center',
                            width: '3rem',
                            render: (_1: any, _2: any, index: any) =>
                              (index + 1).toString(),
                          },
                          {
                            title: '药品名称',
                            dataIndex: 'name',
                            align: 'center',
                            key: 'name',
                            width: '12rem',
                            ellipsis: true,
                            render: function Element(_, record) {
                              return (
                                <Popover
                                content={<div>
                                  <p
                                    style={{
                                      height: '28px',
                                      lineHeight: '36px',
                                      padding: '0',
                                      margin: '0',
                                      fontSize: '16px',
                                    }}
                                  >
                                    {record.name}
                                  </p>
                                  <p
                                    style={{
                                      lineHeight: '24px',
                                      padding: '0',
                                      margin: '0',
                                      color: '#999999',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {record.drugInfo}
                                  </p>
                                </div>}
                                placement='bottom'
                              >
                                <div>
                                  <p
                                    style={{
                                      height: '28px',
                                      lineHeight: '36px',
                                      padding: '0',
                                      margin: '0',
                                      fontSize: '16px',
                                    }}
                                  >
                                    {record.name}
                                  </p>
                                  <p
                                    style={{
                                      lineHeight: '24px',
                                      padding: '0',
                                      margin: '0',
                                      color: '#999999',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {record.drugInfo}
                                  </p>
                                </div>
                              </Popover>
                              
                              )
                            },
                          },
                          {
                            title: '用法',
                            dataIndex: 'normalUsemethod',
                            align: 'center',
                            key: 'normalUsemethod',
                            width: '4rem',
                            ellipsis: true,
                          },
                          {
                            title: '频次',
                            dataIndex: 'normalFrequency',
                            align: 'center',
                            key: 'normalFrequency',
                            width: '6rem',
                            ellipsis: true,
                          },
                          {
                            title: '每次用量',
                            dataIndex: 'normalDose',
                            align: 'center',
                            key: 'normalDose',
                            width: '4rem',
                            ellipsis: true,
                          },
                          {
                            title: '天数',
                            dataIndex: 'useDays',
                            align: 'center',
                            key: 'useDays',
                            width: '4rem',
                            ellipsis: true,
                          },
                          {
                            title: '数量',
                            dataIndex: 'drugCount',
                            align: 'center',
                            key: 'drugCount',
                            width: '4rem',
                            ellipsis: true,
                          },
                        ]
                    }
                  ></Table>
                </div>
              </>
            )
          })}
        </Col>
      </Modal>
    </>
  )
}

export const OrderManagementList = forwardRef(OrderManagement)

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-12 14:05:34
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface OnlineConsultantListParams {
  current: number
  size: number
  name?: any
  departmentId?: any
  doctorId?: any
  status?: any
}

// 列表
export function OnlineConsultantList(
  params: OnlineConsultantListParams
): Request {
  return {
    url: `${API_PREFIX}/order/consultationOrders`,
    method: 'GET',
    params
  }
}


// 详情
export interface detailPrams {
  consultationId?: any
}

export function detail(
  params: detailPrams
): Request {
  return {
    url: `${API_PREFIX}/consultationrecord/detail`,
    method: 'POST',
    params
  }
}


export function getTenantDoctors(
): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/getTenantDoctors`,
    method: 'GET',
  }
}



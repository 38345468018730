import {
  CloseOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Carousel,
  Col,
  Form,
  Input,
  Image,
  notification,
  Row,
  Upload,
  Select,
  Space,
  Checkbox,
  Radio,
  Table,
  Modal,
  InputNumber,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { getPotionWayName } from '../../models/classicalPrescription'
import { getBase64, tempArr } from '../../utils/StringUtils'
import { getOssUrl } from '../DepartmentManagement/departmentManagementSlice'
import { AddOrEditQuestionModal } from './addOrEditQuestionModal'
import { AddPrescriptionModal } from './addPrescriptionModal'
import { BriefDescriptionModal } from './briefDescriptionModal'
import styles from './classicalPrescription.module.css'
import {
  getAddOrEditClassicalPrescriptionSubmit,
  getClassicalPrescriptionDetail,
  getClassicalPrescriptionOssUrl,
  getDoctorListAsync,
  getPrescriptionTypeListAsync,
  getQuestionDeleteAsync,
  getQuestionListAsync,
} from './classicalPrescriptionSlice'
import { prescriptionColumns, questionColumns } from './columns'
import { DecoctingSettingModal } from './decoctingSettingModal'
import { TypeSettingModal } from './typeSettingModal'

export const AddOrEditPrescription = (props: { id?: any }): ReactElement => {
  const { Option } = Select

  const history = useHistory()

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [imageUrl, setImageUrl] = useState<any>('') //附件，用于传给后端的oss路径参数

  const [decoctingSettingIsVisible, setDecoctingSettingIsVisible] = useState(
    false
  )

  const [submitLoading, setSubmitLoading] = useState(false)

  const [typeSettingIsVisible, setTypeSettingIsVisible] = useState(false)

  const [addOrEditQuestionIsVisible, setAddOrEditQuestionIsVisible] = useState(
    false
  )

  const [
    addOrEditBriefDescriptionVisible,
    setAddOrEditBriefDescriptionVisible,
  ] = useState(false)

  const [addPrescriptionIsVisible, setAddPrescriptionIsVisible] = useState(
    false
  )

  const [radioType, setRadioType] = useState(1)

  const [detail, setDetail] = useState<any>()

  const [questionData, setQuestionData] = useState<any>() //问题数据暂存做编辑删除对比用到

  const [imgList, setImgList] = useState<any>([])

  const [fileList, setFileList] = useState<File[]>([]) //文件

  const [prescriptionList, setPrescriptionList] = useState<any>() //方剂列表

  const [questionList, setQuestionList] = useState<any>([]) //问题列表

  const [doctorList, setDoctorList] = useState<any>([]) //医生列表

  const [prescriptionTypeList, setPrescriptionTypeList] = useState<any>() //方剂类型

  const [decotingModalValues, setDecotingModalValues] = useState<any>({}) //设置代煎的三个值

  const [decoctingSettingDetail, setDecoctingSettingDetail] = useState<any>({}) //代煎回填的值

  const [modalData, setModalData] = useState<any>({})

  const rowProps = {
    gutter: 4,
  }

  const colProps = { span: 8 }

  const firstColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 16 },
  }

  const allColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 20 },
  }

  // 预诊问题
  useEffect(() => {
    if (location?.state?.id) {
      getDetail()
    } else {
      form.setFieldsValue({
        formulaNumberLimit: 0,
        potionType: 1,
      })
    }
  }, [location?.state?.id])

  useEffect(() => {
    if (imgList?.length) {
      form.setFieldsValue({
        formulaImg: imgList
          ?.map((v: any) => {
            return v?.imgBaseInfo
          })
          ?.join('@$$@'),
      })
    } else {
      form.setFieldsValue({
        formulaImg: '',
      })
    }
  }, [imgList])

  useEffect(() => {
    if (questionList?.length)
      form.setFieldsValue({
        questionList: questionList,
      })
  }, [questionList])

  useEffect(() => {
    getPrescriptionTypeList()
    getDoctorList()
  }, [])

  // 问题列表
  const getQuestionList = () => {
    dispatch(getQuestionListAsync({ formule_id: location?.state?.id }))
      .then(unwrapResult)
      .then((v: any) => {
        setQuestionList(v)
      })
  }

  // 方剂详情
  const getDetail = () => {
    dispatch(getClassicalPrescriptionDetail(location?.state?.id))
      .then(unwrapResult)
      .then((v: any) => {
        setDetail(v)
        form.setFieldsValue({
          ...v,
          decotingValues: v?.potionWay != -1 ? v?.potionWay?.toString() : '',
          formulaDocId:
            v?.formulaDocId && v?.formulaDocId != -1 ? v?.formulaDocId : null,
          formulaPrice:
            v?.formulaPrice && v?.formulaPrice != -1
              ? v?.formulaPrice?.toFixed(2)
              : '',
          formulaSort:
            Number(v?.formulaSort) != -1 ? Number(v?.formulaSort) : null,
        })

        const imgList = v?.formulaImg
          ? v.formulaImg?.split('@$$@')?.map((v: any) => {
              return { imgBaseInfo: v }
            })
          : []

        setQuestionList(v?.questionList)
        setRadioType(v?.potionType)
        // if (v?.potionType?.toString() == '0') {
        //   setDecoctingSettingDetail({})
        // } else {
          setDecotingModalValues({
            potionWay: v?.potionWay || 1,
            potionNorm: v?.potionNorm,
            potionNote: v?.potionNote,
          })
        // }
        setPrescriptionList(
          v?.detailList?.map((n: any) => {
            return {
              ...n,
              itemName: n?.medicineName,
              count: n?.medicineCount,
              normalUseMethod: n?.medicineUsage,
            }
          })
        )
        setImgList(imgList)
      })
  }

  useEffect(() => {
    if (prescriptionTypeList?.length && detail?.formulaType) {
      form.setFieldsValue({
        formulaType: prescriptionTypeList?.filter(
          (n: any) => n?.enumValue == detail?.formulaType
        )?.length
          ? detail?.formulaType
          : null,
      })
    }
  }, [prescriptionTypeList, detail])

  useEffect(() => {
    if (detail?.formulaDocId && doctorList?.length) {
      form.setFieldsValue({
        formulaDocId: doctorList?.find(
          (n: any) => n?.id == detail?.formulaDocId
        )
          ? detail?.formulaDocId
          : doctorList?.find((v: any) => v?.id == detail?.formulaDocId)
          ? doctorList?.find((v: any) => v?.id == detail?.formulaDocId)
              ?.doctorName
          : null,
      })
    }
  }, [doctorList, detail])

  const refreshQuestionList = () => {
    dispatch(getClassicalPrescriptionDetail(location?.state?.id))
      .then(unwrapResult)
      .then((v: any) => {
        setQuestionList(v?.questionList)
      })
  }

  // 方剂类型
  const getPrescriptionTypeList = () => {
    dispatch(getPrescriptionTypeListAsync({ enumType: 'formula' }))
      .then(unwrapResult)
      .then((v) => {
        setPrescriptionTypeList(v)
      })
  }

  // 医生列表
  const getDoctorList = () => {
    dispatch(getDoctorListAsync())
      .then(unwrapResult)
      .then((v: any) => {
        setDoctorList(v)
      })
  }

  const refreshData = () => {
    form.resetFields()
    setImgList([])
    setFileList([])
    setQuestionData([])
    setPrescriptionList([])
    setPrescriptionTypeList([])
    setDecoctingSettingDetail({})
    setDecotingModalValues({})
    setRadioType(1)
    setModalData({})
  }

  return (
    <>
      <NavBar
        style={{ margin: '10px 20px 0 20px' }}
        where={['经典方剂', location?.state?.id ? '方剂编辑' : '方剂新增']}
        backtrace={{
          name: '经典方剂',
          path: '/admin/classicalPrescription',
          routeName: '便捷配药管理',
          state: {},
        }}
        buttons={
          <div
            style={{
              display: 'flex',
            }}
          ></div>
        }
      />
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.left}>
            <Form
              className={styles.form}
              name='basic'
              form={form}
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 21,
              }}
              onFinish={(values) => {
                setSubmitLoading(true)
                const formulaImgList = imgList
                  ?.map((v: any) => {
                    return v?.imgBaseInfo
                  })
                  ?.join('@$$@')

                const params = {
                  ...values,
                  decotingValues: null,
                  formulaImg: formulaImgList,
                  potionWay: decotingModalValues?.potionWay?.toString(),
                  potionNorm: decotingModalValues?.potionNorm,
                  potionNote: decotingModalValues?.potionNote,
                  formulaDoc: doctorList?.find(
                    (v: any) => v?.id == values?.formulaDocId
                  )?.doctorName,
                  id: location?.state?.id ? detail?.id : '',
                  synopsis:modalData?.synopsis,
                  explanation:modalData?.explanation
                }

                dispatch(getAddOrEditClassicalPrescriptionSubmit(params))
                  .then(unwrapResult)
                  .then((v) => {
                    notification.success({
                      message: '操作成功',
                    })
                    history.push({
                      pathname: '/admin/classicalPrescription',
                    })
                    refreshData()
                    setSubmitLoading(false)
                  })
                  .catch(() => {
                    setSubmitLoading(false)
                    setModalData({})
                  })
              }}
            >
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        {...firstColumnLayout}
                        name='formulaType'
                        label='方剂类型'
                        required
                        rules={[
                          { required: true, message: '方剂类型不能为空' },
                        ]}
                      >
                        <Select placeholder='请选择方剂类型' allowClear>
                          {prescriptionTypeList?.map((v: any) => {
                            return (
                              <Option value={v?.enumValue} key={v?.enumValue}>
                                {v?.enumName}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      span={3}
                      style={{ marginLeft: '10px', marginTop: '4px' }}
                    >
                      <SettingOutlined
                        style={{ fontSize: '24px', color: '#027AFF' }}
                        onClick={() => {
                          setTypeSettingIsVisible(true)
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    label='方剂选择'
                    name='detailList'
                    {...allColumnLayout}
                    rules={[{ required: true, message: '方剂选择不能为空' }]}
                  >
                    <Button
                      type='primary'
                      size='middle'
                      onClick={() => {
                        setAddPrescriptionIsVisible(true)
                      }}
                    >
                      添加方剂
                    </Button>
                    {prescriptionList?.length ? (
                      <EditableList
                        style={{ marginTop: '20px' }}
                        bordered
                        page={{
                          items: prescriptionList,
                          current: 1,
                          size: 10,
                          total: 10,
                        }}
                        columns={prescriptionColumns()}
                        pagination={false}
                      />
                    ) : (
                      <></>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={20}>
                  <Form.Item
                    {...firstColumnLayout}
                    label='方剂名称'
                    required
                    name='formulaName'
                    rules={[{ required: true, message: '方剂名称不能为空' }]}
                  >
                    <Input.TextArea
                      maxLength={20}
                      showCount
                      placeholder='请输入'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Row style={{ lineHeight: '32px' }}>
                    <Col span={12}>
                      <Form.Item
                        {...firstColumnLayout}
                        name='formulaNumber'
                        label='起购帖数'
                        required
                        rules={[
                          { required: true, message: '起购帖数不能为空' },
                          {
                            pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                            message: '请输入数字',
                          },
                        ]}
                      >
                        <Input placeholder='请输入' />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ marginLeft: '10px' }}>
                      帖
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='formulaNumberLimit'
                    label='帖数限制'
                    required
                    rules={[{ required: true, message: '帖数限制不能为空' }]}
                  >
                    <Radio.Group
                      defaultValue={1}
                      value={radioType}
                      onChange={(e) => {
                        return
                      }}
                    >
                      <Radio value={0} style={{ width: '100px' }}>
                        不限制
                      </Radio>
                      <Radio value={1}>起购数的倍数</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='potionType'
                    label='代煎类型'
                    required
                    rules={[{ required: true, message: '代煎类型不能为空' }]}
                  >
                    <Radio.Group
                      defaultValue={1}
                      value={radioType}
                      onChange={(e) => {
                        setRadioType(e?.target?.value)
                        setDecoctingSettingDetail({})
                      }}
                    >
                      <Radio value={0} style={{ width: '100px' }}>
                        自煎
                      </Radio>
                      <Radio value={1} style={{ width: '100px' }}>代煎</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row> */}
              {/* {radioType == 0 ? (
                <></>
              ) : ( */}
                <Row {...rowProps}>
                  <Col {...colProps} span={24}>
                    <Form.Item
                      label='代煎设置'
                      name='decotingValues'
                      {...allColumnLayout}
                      required
                      rules={[{ required: true, message: '代煎设置不能为空' }]}
                    >
                      <Button
                        type='primary'
                        size='middle'
                        style={{ marginRight: '20px' }}
                        onClick={() => {
                          setDecoctingSettingIsVisible(true)
                          setDecoctingSettingDetail(decotingModalValues)
                        }}
                      >
                        代煎设置
                      </Button>
                      {decotingModalValues?.potionWay?.toString()
                        ? getPotionWayName(
                            decotingModalValues?.potionWay?.toString()
                          )
                        : ''}
                      {decotingModalValues?.potionNorm ? ' , ' : ''}
                      {decotingModalValues?.potionNorm
                        ? decotingModalValues?.potionNorm
                        : ''}
                      {decotingModalValues?.potionNote ? ' , ' : ''}
                      {decotingModalValues?.potionNote
                        ? decotingModalValues?.potionNote
                        : ''}
                    </Form.Item>
                  </Col>
                </Row>
              {/* )} */}
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='useMethod'
                    label='处方用法'
                    required
                    rules={[{ required: true, message: '处方用法不能为空' }]}
                  >
                    <Radio.Group
                      defaultValue={1}
                      onChange={(e) => {
                        return
                      }}
                    >
                      <Radio value={1} style={{ width: '100px' }}>
                        内服
                      </Radio>
                      <Radio value={2} style={{ width: '100px' }}>外用</Radio>
                      <Radio value={3} style={{ width: '100px' }}>灌肠</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={10}>
                  <Form.Item
                    {...firstColumnLayout}
                    label='每帖价格'
                    required
                    name='formulaPrice'
                    rules={[
                      { required: true, message: '每帖价格不能为空' },
                      {
                        pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                        message: '请输入最多带两位小数的数值',
                      },
                    ]}
                  >
                    <Input addonAfter='元' placeholder='请输入' />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    label='图片'
                    name='formulaImg'
                    {...allColumnLayout}
                    rules={[{ required: true, message: '请上传图片' }]}
                  >
                    <Col className={styles.upload}>
                      {imgList?.length < 5 && (
                        <Upload
                          beforeUpload={(file) => {
                            if (file.size / 1024 < 500) {
                              return true
                            } else {
                              notification.info({
                                message: '图片大于500kb,请重新选择!',
                              })
                              return false
                            }
                          }}
                          maxCount={10}
                          showUploadList={false}
                          customRequest={async ({ file }) => {
                            const formData = new FormData()
                            // 向 FormData 中添加新的属性值 append()方法
                            formData.append('file', file)
                            if (file instanceof File) {
                              dispatch(getClassicalPrescriptionOssUrl(formData))
                                .then(unwrapResult)
                                .then((v: any) => {
                                  setImageUrl(v)
                                  setImgList([
                                    //保存预览图片
                                    ...imgList,
                                    {
                                      imgBaseInfo: v,
                                    },
                                  ])
                                })
                                .catch(() => {
                                  return
                                })

                              setFileList([
                                //保存 file文件
                                ...fileList,
                                file,
                              ])
                            }
                          }}
                        >
                          <div className={styles.uploadButton}>
                            上传图片
                            {/* <PlusOutlined className={styles.uploadButtonImg} /> */}
                          </div>
                        </Upload>
                      )}
                      <div className={styles.uploadText}>
                        最多支持5张，第1张作为头图，支持jpeg、jpg、png等图片格式
                      </div>
                    </Col>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {imgList?.length != 0 &&
                        [...imgList]?.map((v: any, index: number) => {
                          return (
                            <div key={index} className={styles.imgWrap}>
                              <span
                                className={styles.imgClose}
                                onClick={(i) => {
                                  setImgList(imgList.filter((i: any) => i != v))
                                  setFileList(
                                    tempArr(fileList, 'uid').filter(
                                      (i: any) => i != v
                                    )
                                  )
                                  notification.success({
                                    message: `删除成功`,
                                  })
                                }}
                              >
                                <CloseOutlined />
                              </span>
                              {v?.imgBaseInfo ? (
                                <img
                                  className={styles.img}
                                  src={v?.imgBaseInfo || v?.info}
                                />
                              ) : (
                                <img className={styles.img} src={v?.info} />
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    {...allColumnLayout}
                    name='formulaDetail'
                    label='详情'
                  >
                    <TextArea
                      showCount
                      maxLength={1000}
                      rows={6}
                      placeholder='请输入'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    label='预诊'
                    name='questionList'
                    {...allColumnLayout}
                  >
                    <Button
                      type='primary'
                      size='middle'
                      onClick={() => {
                        setQuestionData({})
                        setAddOrEditQuestionIsVisible(true)
                      }}
                    >
                      添加问题
                    </Button>

                    <Button
                      type='primary'
                      size='middle'
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        setAddOrEditBriefDescriptionVisible(true)
                      }}
                    >
                      添加简介说明
                    </Button>
                    {questionList?.length ? (
                      <EditableList
                        style={{ marginTop: '20px' }}
                        bordered
                        page={{
                          items: questionList,
                          current: 1,
                          size: 10,
                          total: 10,
                        }}
                        columns={questionColumns((action: any, v: any) => {
                          switch (action) {
                            case 'edit':
                              if (!v?.id) {
                                setQuestionData({
                                  ...v,
                                  oldName: v?.questionName,
                                  oldSort: v?.questionSort,
                                })
                              } else {
                                setQuestionData({
                                  id: v?.id,
                                })
                              }
                              setAddOrEditQuestionIsVisible(true)
                              break
                            case 'delete':
                              Modal.confirm({
                                centered: true,
                                title: '',
                                content: <div>是否确认删除？</div>,
                                okText: '确认',
                                cancelText: '取消',
                                icon: null,
                                onOk: () => {
                                  // 数据落地后端保存
                                  if (v?.id) {
                                    dispatch(
                                      getQuestionDeleteAsync({ id: v?.id })
                                    )
                                      .then(unwrapResult)
                                      .then((v) => {
                                        notification.success({
                                          message: '操作成功',
                                        })
                                        refreshQuestionList()
                                      })
                                  } else {
                                    // 前端进行删除
                                    const filterData = questionList?.filter(
                                      (c: any) =>
                                        c?.questionName != v?.questionName
                                    )
                                    setQuestionList(filterData)
                                    notification.success({
                                      message: '操作成功',
                                    })
                                  }
                                },
                              })
                              break
                          }
                        })}
                        pagination={false}
                      />
                    ) : (
                      <></>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='formulaDocId'
                    label='接诊医生'
                    rules={[{ required: true, message: '接诊医生不能为空' }]}
                  >
                    <Select placeholder='请选择接诊医生' allowClear>
                      {doctorList?.map((v: any) => {
                        return (
                          <Option value={v?.id} key={v?.id}>
                            {v?.realname}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='formulaDocDiagnosis'
                    label='诊断'
                    rules={[{ required: true, message: '诊断不能为空' }]}
                  >
                    <Input placeholder='请输入' allowClear></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='formulaSort'
                    label='排序'
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                        message: '请输入数字',
                      },
                    ]}
                  >
                    <Input allowClear placeholder='请输入'></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  width: '77vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  ghost
                  type='primary'
                  style={{
                    marginLeft: '200px',
                  }}
                  onClick={() => {
                    refreshData()
                    history.push({
                      pathname: '/admin/classicalPrescription',
                    })
                  }}
                >
                  取消
                </Button>
                <Button
                  type='primary'
                  loading={submitLoading}
                  // htmlType='submit'
                  style={{
                    marginLeft: '20px',
                  }}
                  onClick={() => {
                    form.submit()
                  }}
                >
                  保存
                </Button>
              </Row>
            </Form>
          </div>
        </div>
        {/* 代煎设置 */}
        <DecoctingSettingModal
          // 代煎回填的信息
          decoctingSettingDetail={decoctingSettingDetail}
          visible={decoctingSettingIsVisible}
          onOk={() => {
            setDecoctingSettingIsVisible(false)
          }}
          onCancel={() => {
            setDecoctingSettingIsVisible(false)
          }}
          // 代煎设置的三个值
          decotingModalValues={(v) => {
            setDecotingModalValues(v)
            form.setFieldsValue({
              decotingValues: v?.potionWay?.toString(),
            })
          }}
        ></DecoctingSettingModal>

        {/* 类型设置 */}
        <TypeSettingModal
          visible={typeSettingIsVisible}
          onOk={() => {
            setTypeSettingIsVisible(false)
          }}
          onCancel={() => {
            setTypeSettingIsVisible(false)
            getPrescriptionTypeList()
          }}
        ></TypeSettingModal>

        {/* 问题新增编辑 */}
        <AddOrEditQuestionModal
          formulaId={location?.state?.id} //方剂列表id
          questionData={questionData}
          visible={addOrEditQuestionIsVisible}
          onOk={() => {
            setAddOrEditQuestionIsVisible(false)
          }}
          onCancel={() => {
            setAddOrEditQuestionIsVisible(false)
          }}
          // 添加问题数据暂存前端（没生成方剂时）
          addQuestion={(v) => {
            if (
              questionList?.find((n: any) => n?.questionName == v?.questionName)
            ) {
              notification.info({
                message: '已添加过该问题',
              })
            } else {
              if (JSON.stringify(questionData) != '{}') {
                //  editQuestionData(values)
              } else {
                setQuestionList(
                  [...questionList, v]?.sort(
                    (a: any, b: any) => a.questionSort - b?.questionSort
                  )
                )
              }
            }
          }}
          // 编辑问题暂存前端（没生成方剂时）
          editQuestion={(v) => {
            const data = questionList?.map((val: any) => {
              return {
                ...val,
                questionName:
                  val?.questionName == v?.oldName
                    ? v?.questionName
                    : val?.questionName,
                questionSort:
                  val?.questionSort == v?.oldSort
                    ? v?.questionSort
                    : val?.questionSort,
              }
            })

            setQuestionList(
              data?.sort((a: any, b: any) => a.questionSort - b?.questionSort)
            )
          }}
          refreshDetail={() => {
            refreshQuestionList()
          }}
        ></AddOrEditQuestionModal>

        {/* 添加或编辑简介说明 */}
        <BriefDescriptionModal
        detail={detail}
          visible={addOrEditBriefDescriptionVisible}
          onOk={() => {
            setAddOrEditBriefDescriptionVisible(false)
          }}
          onCancel={() => {
            setAddOrEditBriefDescriptionVisible(false)
          }}
          onValueChange={((v)=>{
            setAddOrEditBriefDescriptionVisible(false)
            console.log(v,"ddddddddddd");
            setModalData(v)
          })}
        />

        {/* 添加方剂 */}
        <AddPrescriptionModal
          visible={addPrescriptionIsVisible}
          onOk={() => {
            setAddPrescriptionIsVisible(false)
          }}
          onCancel={() => {
            setAddPrescriptionIsVisible(false)
          }}
          // 添加方剂数据暂存前端（没生成方剂时）
          addMedical={(v, n) => {
            setPrescriptionList(v)
            if (!form.getFieldValue('formulaName')) {
              form.setFieldsValue({
                formulaName: n,
              })
            }
            form.setFieldsValue({
              detailList: v,
            })
          }}
        ></AddPrescriptionModal>
      </div>
    </>
  )
}

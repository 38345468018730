import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import {
  getClassicalPrescriptionGrounding,
  getClassicalPrescriptionList,
  getClassicalPrescriptionUndercarriage,
  getDeleteSubmit,
} from './classicalPrescriptionSlice'

export interface ClassicalPrescriptionRef {
  refresh?: () => void
  tabKey?: any
}

interface ClassicalPrescriptionProps {
  tabKey?: any
}
const RealClassicalPrescriptions = (
  { tabKey }: ClassicalPrescriptionProps,
  ref: React.Ref<ClassicalPrescriptionRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const history: any = useHistory()

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [sortedInfo, setSorterInfo] = useState<any>()

  const [id, setId] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getClassicalPrescriptionList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params) {
      getPage()
    }
  }, [params])

  useEffect(() => {
    if (sortedInfo?.order === 'ascend' && sortedInfo?.columnKey === 'formulaSale') {
      setParams({
        ...params,
        sort: 0,
      })
    } else if (
      sortedInfo?.order === 'descend' &&
      sortedInfo?.columnKey === 'formulaSale'
    ) {
      setParams({
        ...params,
        sort: 1,
      })
    } else if (sortedInfo && sortedInfo?.order == undefined) {
      setParams({
        ...params,
        sort: undefined,
      })
    }
  }, [sortedInfo])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='经典方剂'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '15px  15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Query
              onValueChange={(v: any) => {
                setParams({
                  ...params,
                  current: 1,
                  ...v,
                })
                setSorterInfo(undefined)
              }}
              addPrescription={() => {
                history.push({
                  pathname: '/admin/addOrEditPrescription',
                })
              }}
            />
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              onRow={(v: any) => ({
                onDoubleClick: () => {
                  setId(v.id)
                  history.push({
                    pathname: '/admin/addOrEditPrescription',
                    state: {
                      id: v?.id,
                    },
                  })
                },
              })}
              columns={columns(
                (action: any, v: any) => {
                  switch (action) {
                    case 'detail':
                      // setIsHealthDetailVisible(true)
                      setId(v?.id)
                      break
                    case 'edit':
                      setId(v.id)
                      history.push({
                        pathname: '/admin/addOrEditPrescription',
                        state: {
                          id: v?.id,
                        },
                      })
                      break
                    case 'grounding':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认上架？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          dispatch(
                            getClassicalPrescriptionGrounding({ id: v?.id })
                          )
                            .then(unwrapResult)
                            .then((v) => {
                              notification.success({
                                message: '操作成功',
                                duration: 3,
                              })
                              getPage()
                            })
                        },
                      })
                      break
                    case 'undercarriage':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认下架？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          dispatch(
                            getClassicalPrescriptionUndercarriage({ id: v?.id })
                          )
                            .then(unwrapResult)
                            .then((v) => {
                              notification.success({
                                message: '操作成功',
                                duration: 3,
                              })
                              getPage()
                            })
                        },
                      })
                      break
                    case 'delete':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认删除？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          dispatch(getDeleteSubmit({ id: v?.id }))
                            .then(unwrapResult)
                            .then((v) => {
                              notification.success({
                                message: '操作成功',
                                duration: 3,
                              })
                              getPage()
                            })
                        },
                      })
                      break
                  }
                },
                params.size * (params.current - 1),
                sortedInfo
              )}
              onChange={(a, b, sorter: any) => {
                setSorterInfo(sorter)
              }}
              onChangePage={(current, size) => {
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const ClassicalPrescriptionList = forwardRef(RealClassicalPrescriptions)

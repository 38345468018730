export enum ReleaseStatusCategory {
  CSZ = '0',
  CKZ = '1',
}
// 全部
export const ReleaseStatusCategories = [
  ReleaseStatusCategory.CSZ,
  ReleaseStatusCategory.CKZ,
]

export function getReleaseStatusName(state: ReleaseStatusCategory): string {
  switch (state) {
    case '0':
      return '仓库中'
    case '1':
      return '出售中'
  }
  return ''
}

export enum ReleaseTypeCategory {
  QB = '0',
  CY = '1',
  GF = '2',
  YSF = '3',
  WYF = '4',
}
// 全部
export const ReleaseTypeCategories = [
  ReleaseTypeCategory.QB,
  ReleaseTypeCategory.CY,
  ReleaseTypeCategory.GF,
  ReleaseTypeCategory.YSF,
  ReleaseTypeCategory.WYF,
]

export function getReleaseTypeName(state: ReleaseTypeCategory): string {
  switch (state) {
    case '0':
      return '全部'
    case '1':
      return '茶饮'
    case '2':
      return '膏方'
    case '3':
      return '药膳方'
    case '4':
      return '外用方'
  }
  return ''
}

export function getPotionWayName(potionWay: any): string {
  switch (potionWay) {
    case '1':
      return '代煎'
    case '2':
      return '自煎'
    case '3':
      return '膏方代煎'
    case '7':
      return '制丸'
    case '8':
      return '打粉'
  }
  return ''
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-21 17:22:52
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-21 15:08:54
 */
export enum OrderTypeCategory {
  BJPY = '1',
  ZXPF = '2',
  ZXFZ = '3',
  TWZX = '4',
  DHZX = '5',
  ZXFZXF = '6',
}
// 全部
export const ReleaseStateCategories = [
  OrderTypeCategory.BJPY,
  OrderTypeCategory.ZXPF,
  OrderTypeCategory.ZXFZ,
  OrderTypeCategory.TWZX,
  OrderTypeCategory.DHZX,
  OrderTypeCategory.ZXFZXF
]

export function getReleaseStateName(orderState: OrderTypeCategory): string {
  switch (orderState) {
    case '1':
      return '便捷配药'
    case '2':
      return '在线拍方'
    case '3':
      return '在线复诊'
    case '4':
      return '图文咨询'
    case '5':
      return '电话咨询'
    case '6':
      return '在线复诊续方'
  }
  return ''
}

// 0：未支付，1：已支付进行中 2：已退款 3：已完成 9：已取消
export enum OrderStateCategory {
  WZF = '0',
  YZFJXZ = '1',
  YTK = '2',
  YWC = '3',
  DSH = '4',
  DKF = '5',
  SHBTG = '6',
  YQX = '9',
}
// 全部
export const ReleaseOrderStateCategories = [
  OrderStateCategory.WZF,
  OrderStateCategory.YZFJXZ,
  OrderStateCategory.YTK,
  OrderStateCategory.YWC,
  OrderStateCategory.DSH,
  OrderStateCategory.DKF,
  OrderStateCategory.SHBTG,
  OrderStateCategory.YQX,
]

export function getOrderStateName(state: OrderStateCategory): string {
  switch (state) {
    case '0':
      return '待支付'
    case '1':
      return '进行中'
    case '2':
      return '已退款'
    case '3':
      return '已完成'
    case '4':
      return '待审核'
    case '5':
      return '待开方'
    case '6':
      return '审核不通过'
    case '9':
      return '已取消'
  }
  return ''
}

import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { DateTimeFormatTimeSimple } from '../../models/datetime'
import {
  getOrderStateName,
  getReleaseStateName,
} from '../../models/orderManagemnet'
import { getGenderName } from '../../models/user'

export const columns = (
  onClick: (action: any, v?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'age',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.age
      },
    },
    {
      title: '身份证号',
      dataIndex: 'idCard',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.idCard ? r.idCard : '-'
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '类型',
      dataIndex: 'orderType',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.orderType ? getReleaseStateName(r?.orderType?.toString()) : '-'
      },
    },
    {
      title: '明细',
      dataIndex: 'comment',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.comment ? r.comment : '-'
      },
    },
    {
      title: '数量',
      dataIndex: 'count',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.count != -1 ? r.count : '-'
      },
    },
    {
      title: '金额',
      dataIndex: 'payAmount',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.payAmount != -1 ? r.payAmount?.toFixed(2) : '-'
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.createTime
          ? moment(r.createTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '付款时间',
      dataIndex: 'payTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.payTime
          ? moment(r.payTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.state?.toString()
          ? getOrderStateName(r?.state?.toString())
          : '-'
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '5rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('detail', u)}
              style={{ marginRight: '4px' }}
            >
              详情
            </a>
          </>
        )
      },
    },
  ]
}

//续方
export const XuFangColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '2rem',
      ellipsis: true,
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: '药品名称',
      key: 'name',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    // {
    //   title: '每帖克数',
    //   key: 'order',
    //   align: 'center',
    //   width: '3rem',
    //   ellipsis: true,
    // },
    {
      title: '总数量',
      key: 'drugCount',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_, r) => {
        return r.drugCount ? r.drugCount : '-'
      },
    },
    {
      title: '用法',
      key: 'normalUsemethod',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_, r) => {
        return r.normalUsemethod ? r.normalUsemethod : '-'
      },
    },
  ]
}

//便捷配药
export const BJPYColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '2rem',
      ellipsis: true,
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: '药品名称',
      key: 'order',
      align: 'left',
      width: '5rem',
      ellipsis: true,
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: '用法',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
    {
      title: '频次',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
    {
      title: '每次用量',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
    {
      title: '天数',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
    {
      title: '数量',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
  ]
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-16 16:02:40
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

// 列表
export interface OrderManagementListPrams {
  current: number
  size: number
  name?: any
  orderType?: any
  state?: any
}

export function orderManagementList(
  params: OrderManagementListPrams
): Request {
  return {
    url: `${API_PREFIX}/order/list`,
    method: 'GET',
    params,
  }
}



// 列表
export interface OrderManagementDetailPrams {
  orderId?: any
}

export function orderManagementDetail(
  params: OrderManagementDetailPrams
): Request {
  return {
    url: `${API_PREFIX}/order/detail`,
    method: 'GET',
    params,
  }
}

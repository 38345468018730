import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  getOrderStateName,
  getReleaseStateName,
  ReleaseOrderStateCategories,
  ReleaseStateCategories,
} from '../../models/orderManagemnet'

interface OrderManagementParams {
  current: number
  size: number
  startTime: string
  endTime: string
}

export const Query = (props: {
  onValueChange: (queries: OrderManagementParams) => void
}): ReactElement => {
  const [form] = Form.useForm()
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='姓名/手机号/身份证号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='orderType'>
              <Select
                style={{ width: '10rem' }}
                placeholder='类型'
                allowClear
                onChange={form.submit}
              >
                {ReleaseStateCategories?.map((v): any => {
                  return (
                    <Select.Option key={v} value={v}>
                      {getReleaseStateName(v)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='state'>
              <Select
                style={{ width: '10rem' }}
                placeholder='状态'
                allowClear
                onChange={form.submit}
              >
                {ReleaseOrderStateCategories?.map((v): any => {
                  return (
                    <Select.Option key={v} value={v}>
                      {getOrderStateName(v)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-15 11:44:01
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-18 10:06:04
 */
import { CaretUpFilled } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  Row,
  Image,
  Table,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatTimeSimple,
} from '../../models/datetime'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import styles from './onlineConsultant.module.css'
import { getDetailAsync } from './onlineConsultantSlice'

interface detailModalProps {
  consultationId?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const DetailModal = ({
  consultationId,
  visible,
  onCancel,
  onOk,
}: detailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const labelStyle = {
    style: {
      textAlign: 'left',
      marginBottom: 10,
      marginRight: '6px',
      // width: '90px',
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      marginBottom: 10,
    } as React.CSSProperties,
  }

  useEffect(() => {
    if (visible) {
      dispatch(getDetailAsync({ consultationId: consultationId }))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [visible])

  return (
    <>
      <Modal
        className={styles.detailModal}
        title={'详情'}
        width={800}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        footer={null}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Row>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>姓名：</Col>
              <Col {...valueStyle}>{detail?.patientName || '-'}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>性别：</Col>
              <Col {...valueStyle}>{getGenderName(detail?.sex)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>年龄：</Col>
              <Col {...valueStyle}>{detail?.age}岁</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>手机号：</Col>
              <Col {...valueStyle}>{detail?.patientPhone}</Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row>
              <Col {...labelStyle}>身份证号：</Col>
              <Col {...valueStyle}>{detail?.identityCard}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>病情描述：</Col>
              <Col style={{ flex: '1', marginBottom: '10px' }}>
                {detail?.description || '-'}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>图片：</Col>
              <Col {...valueStyle}>
                {detail?.pictures ? (
                  detail?.pictures?.split(',')?.map((v: any) => {
                    return (
                      <Image
                        src={v}
                        key={v}
                        style={{
                          width: '60px',
                          height: '60px',
                          marginRight: '10px',
                        }}
                      ></Image>
                    )
                  })
                ) : (
                  <>-</>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Row>
              <Col {...labelStyle}>预约时间：</Col>
              <Col {...valueStyle}>
                {moment(detail?.appointmentTime).format(
                  DateTimeFormatTimeSimple
                ) || '-'}
              </Col>
            </Row>
          </Col>
          {detail?.status == 1 || detail?.status == 2 ? (
            <>
              <Col span={8}>
                <Row>
                  <Col {...labelStyle}>接诊时间：</Col>
                  <Col {...valueStyle}>
                    {detail?.consultationStartTime
                      ? detail?.orderType == 4
                        ? moment(detail?.consultationStartTime).format(
                            DateTimeFormatTimeSimple
                          )
                        : moment(detail?.consultationStartTime).format(
                            DateTimeFormatTimeSimple
                          )
                      : '-'}
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <></>
          )}
          {detail?.status == 9 ? (
            <>
              <Col span={8}>
                <Row>
                  <Col {...labelStyle}>取消时间：</Col>
                  <Col {...valueStyle}>
                    {detail?.cancelTime
                      ? moment(detail?.cancelTime).format(
                          DateTimeFormatTimeSimple
                        )
                      : '-'}
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <></>
          )}

          {detail?.status == 2 ? (
            <>
              <Col span={8}>
                <Row>
                  <Col {...labelStyle}>接诊时长：</Col>
                  <Col {...valueStyle}>{detail?.duration || '-'} 分钟</Col>
                </Row>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </Modal>
    </>
  )
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:00:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-18 13:38:11
 */
import { Button, Col, Tabs, Row, Form, Space, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useState } from 'react'
import { freePatientQueryParams } from '../../services/freePatients'
import { RootDispatch } from '../../app/store'
import { getDepartCountWithUserList } from '../divisionManagement/divisionManagementSlice'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

export const Query = (props: {
  onValueChange: (queries: freePatientQueryParams) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  const [departmentList, setDepartmentList] = useState<any>()

  const dispatch = useDispatch<RootDispatch>()

  useEffect(() => {
    dispatch(getDepartCountWithUserList(''))
      .then(unwrapResult)
      .then((v) => {
        setDepartmentList(v)
      })
  }, [])

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='key' noStyle>
              <Input
                autoComplete='off'
                placeholder='昵称/用户姓名/手机号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='isEmployee'>
              <Select
                placeholder='是否员工'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                <Option value={1} key={1}>
                  是
                </Option>
                <Option value={0} key={0}>
                  否
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name='departmentId' noStyle>
              <Select
                placeholder='所在部门'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {departmentList?.map((v: any) => {
                  return (
                    <Option value={v?.id} key={v?.id}>
                      {v?.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='isManager'>
              <Select
                placeholder='是否主管'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                <Option value={1} key={1}>
                  是
                </Option>
                <Option value={0} key={0}>
                  否
                </Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name='isDoctor'>
              <Select
                placeholder='是否医生'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                <Option value={1} key={1}>
                  是
                </Option>
                <Option value={0} key={0}>
                  否
                </Option>
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

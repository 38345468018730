/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-17 10:27:25
 */

import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { typeSettingColumns } from './columns'
import styles from './classicalPrescription.module.css'
import { AddOrEditTypeSettingModal } from './addOrEditTypeSettingModal'
import {
  getPrescriptionTypeDelete,
  getPrescriptionTypeListAsync,
} from './classicalPrescriptionSlice'

interface typeSettingModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const TypeSettingModal = ({
  visible,
  onCancel,
  onOk,
}: typeSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [id, setId] = useState('')

  const [prescriptionTypeList, setPrescriptionTypeList] = useState<any>() //方剂类型

  const [
    addOrEditTypeSettingIsVisible,
    setAddOrEditTypeSettingIsVisible,
  ] = useState(false)

  useEffect(() => {
    if (visible) {
      getList()
    }
  }, [visible])

  const getList = () => {
    dispatch(getPrescriptionTypeListAsync({enumType:'formula'}))
      .then(unwrapResult)
      .then((v: any) => {
        setPrescriptionTypeList(v)
      })
  }

  return (
    <>
      <Modal
        className={styles.typeSettingModal}
        title='类型设置'
        width={600}
        visible={visible}
        footer={null}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        onOk={() => {
          onOk()
          form.resetFields()
        }}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            return
          }}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '10px',
            }}
          >
            <Button
              type='primary'
              size='middle'
              onClick={() => {
                setId('')
                setAddOrEditTypeSettingIsVisible(true)
              }}
            >
              新增
            </Button>
          </div>
          <div style={{ height: '340px', overflow: 'auto' }}>
            <EditableList
              bordered
              page={{
                items: prescriptionTypeList,
                current: 1,
                size: 10,
                total: 10,
              }}
              // scroll={{y:290}}
              columns={typeSettingColumns((action: any, v: any) => {
                switch (action) {
                  case 'edit':
                    setId(v?.id)
                    setAddOrEditTypeSettingIsVisible(true)
                    break
                  case 'delete':
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认删除？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        dispatch(getPrescriptionTypeDelete({ id: v?.id }))
                          .then(unwrapResult)
                          .then((v: any) => {
                            notification.success({
                              message: '操作成功',
                            })
                            getList()
                            // onOk()
                            // form.resetFields()
                          })
                      },
                    })
                    break
                }
              })}
              pagination={false}
            />
          </div>
        </Form>
      </Modal>
      <AddOrEditTypeSettingModal
        id={id}
        visible={addOrEditTypeSettingIsVisible}
        onOk={() => {
          setAddOrEditTypeSettingIsVisible(false)
          getList()
        }}
        onCancel={() => {
          setAddOrEditTypeSettingIsVisible(false)
        }}
      ></AddOrEditTypeSettingModal>
    </>
  )
}

import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Space,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './classicalPrescription.module.css'
import {
  getQuestionDetailAsync,
  getQuestionEditSubmitAsync,
} from './classicalPrescriptionSlice'

interface addOrEditQuestionModalProps {
  formulaId?: any
  questionData?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
  addQuestion: (v: any) => void
  editQuestion: (v: any) => void
  refreshDetail: () => void
}
export const AddOrEditQuestionModal = ({
  formulaId, //
  questionData,
  visible,
  onCancel,
  onOk,
  addQuestion,
  editQuestion,
  refreshDetail,
}: addOrEditQuestionModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  useEffect(() => {
    // 前端暂存的数据没有id,编辑时将要编辑的字段传过来
    if (visible) {
      if (!questionData?.id) {
        form.setFieldsValue({
          ...questionData,
        })
      } else {
        //  已经落地的数据 ，调取接口回填信息
        dispatch(getQuestionDetailAsync({ id: questionData?.id }))
          .then(unwrapResult)
          .then((v: any) => {
            form.setFieldsValue({
              ...v,
              restrictionOptions:v?.restrictionOptions !=-1 ? v?.restrictionOptions :''
            })
          })
      }
    }
  }, [visible, questionData?.id])

  

  return (
    <>
      <Modal
        title={JSON.stringify(questionData) != '{}' ? '问题编辑' : '问题新增'}
        width={600}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        onOk={() => {
          onOk()
          form.resetFields()
        }}
        footer={null}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            // questionData?.id 数据落地时当条数据编辑的id ,调取接口保存
            // formulaId 列表的id
            if (questionData?.id || formulaId) {
              const params = questionData?.id
                ? {
                    ...values,
                    id: questionData?.id,
                  }
                : {
                    ...values,
                    formulaId: formulaId,
                  }
              dispatch(getQuestionEditSubmitAsync({ ...params }))
                .then(unwrapResult)
                .then((v) => {
                  notification.success({
                    message: '操作成功',
                  })
                  onOk && onOk()
                  form.resetFields()
                  refreshDetail && refreshDetail()
                })
                .catch(() => {
                  onCancel && onCancel()
                  form.resetFields()
                })
            } else {
              if (JSON.stringify(questionData) != '{}') {
                //  前端编辑数据
                editQuestion({
                  ...values,
                  oldName: questionData?.oldName,
                  oldSort: questionData?.oldSort,
                })
              } else {
                addQuestion({
                  ...values,
                  id: '',
                })
              }
              onOk()
              form.resetFields()
            }
          }}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label='标题'
                name='questionName'
                required
                rules={[{ required: true, message: '标题不能为空' }]}
              >
                <Input placeholder='请输入' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label='购买限制'
                name='restrictionOptions'
                rules={[{ required: true, message: '购买限制不能为空' }]}
              >
                <Radio.Group
                  onChange={(e) => {
                    return
                  }}
                >
                  <Radio value={1} style={{ width: '200px' }}>
                    选择“是”则不允许购买
                  </Radio>
                  <Radio value={0}>选择“否”则不允许购买</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label='排序'
                name='questionSort'
                rules={[{ required: true, message: '排序不能为空' }]}
              >
                <InputNumber style={{ width: '200px' }} placeholder='请输入' />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.buttonMain}>
            <Button
              type='primary'
              ghost
              style={{
                color: '#999',
                borderColor: '#999',
              }}
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              style={{
                marginLeft: '20px',
              }}
              onClick={() => {
                form.submit()
              }}
            >
              保存
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 16:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-19 18:29:09
 */
import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  DHZXReleaseStatusCategories,
  getStatusName,
  ReleaseStatusCategories,
} from '../../models/onLineAuctionParty'
import { RootDispatch } from '../../app/store'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { getDoctorListDataAsync } from '../onLineAuctionParty/onLineAuctionPartySlice'
import { getDepartmentManagementList } from '../DepartmentManagement/departmentManagementSlice'
import { getTenantDoctorsAsync } from './onlineConsultantSlice'

export const Query = (props: {
  tabKey?: any
  onValueChange: (queries: any) => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { Option } = Select

  const [departments, setDepartments] = useState<any[]>([])

  const [doctorList, setDoctorList] = useState<any>([]) //医生列表

  useEffect(() => {
    getDepartmentList()
    getDoctorList()
  }, [dispatch])

  const getDepartmentList = () => {
    dispatch(getDepartmentManagementList({ current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((ds: any) => setDepartments(ds?.records))
      .catch(() => {
        // do nothing
      })
  }

  const getDoctorList = () => {
    dispatch(getTenantDoctorsAsync())
      .then(unwrapResult)
      .then((v: any) => {
        setDoctorList(v)
      })
  }

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => {
          props.onValueChange({
            ...vs,
          })
          console.log(vs, 'wwwwwwwwww')
        }}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='search' noStyle>
              <Input
                autoComplete='off'
                placeholder='就诊人姓名/手机号'
                style={{ width: 180 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='departmentId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='科室'
                allowClear
                onChange={form.submit}
              >
                {departments?.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.hisDepartmentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='doctorId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='医生'
                allowClear
                onChange={form.submit}
              >
                {doctorList?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='status'>
              <Select
                placeholder='状态'
                allowClear
                style={{ width: '8rem' }}
                onChange={() => form.submit()}
              >
                {(props?.tabKey == 1 ? DHZXReleaseStatusCategories: ReleaseStatusCategories)?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getStatusName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

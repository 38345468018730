/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-16 15:56:12
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/orderManagement'

//订单列表
export const getOrderManagementList = createAsyncThunk<
  void,
  DT.OrderManagementListPrams,
  RootThunkApi<void>
>('orderList/getOrderManagementList', async (params, api) => {
  return sendAsync(DT.orderManagementList(params), api)
})


// 详情
export const getOrderManagementDetail = createAsyncThunk<
  void,
  DT.OrderManagementDetailPrams,
  RootThunkApi<void>
>('orderList/getOrderManagementDetail', async (params, api) => {
  return sendAsync(DT.orderManagementDetail(params), api)
})
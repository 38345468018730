import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import {
  getClassicalPrescriptionUndercarriage,
  getDeleteSubmit,
} from '../classicalPrescription/classicalPrescriptionSlice'
import { DetailModal } from './detailModal'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import { DateTimeFormatMessageSimple } from '../../models/datetime'
import moment from 'moment'
import { getOnlineConsultantList } from '../onlineConsultant/onlineConsultantSlice'

export interface OnlineReviewListRef {
  refresh?: () => void
  tabKey?: any
}

interface OnlineReviewListProps {
  tabKey?: any
}
const RealOnlineReviewLists = (
  { tabKey }: OnlineReviewListProps,
  ref: React.Ref<OnlineReviewListRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [detailIsModalVisible, setDetailIsModalVisible] = useState(false)

  const [consultationId, setConsultationId] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getOnlineConsultantList({
        ...params,
        current: params.current,
        size: params.size,
        category: 3,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params) {
      getPage()
    }
  }, [params])

  return (
    <>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='在线复诊'>
          <Col
            style={{
              height: 'calc(100% - 30px)',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              margin: ' 10px 0',
              flexDirection: 'column',
            }}
          >
            <Col
              style={{
                flex: 1,
                backgroundColor: theme.pn,
                borderRadius: 10,
                padding: '15px  15px 0 15px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Query
                onValueChange={(v: any) => {
                  setParams({
                    ...params,
                    current: 1,
                    ...v,
                  })
                }}
              />
              <EditableList
                rowKey={(_, i) => `${i}}`}
                page={{
                  items: page.items,
                  current: params.current,
                  size: params.size,
                  total: page.total,
                }}
                loading={pageLoading}
                columns={columns((action: any, v: any) => {
                  switch (action) {
                    case 'detail':
                      setConsultationId(v?.consultationId)
                      setDetailIsModalVisible(true)
                      break
                  }
                }, params.size * (params.current - 1))}
                onChangePage={(current, size) => {
                  console.log(params, 'change')
                  setParams({ ...params, current, size: size || 10 })
                }}
              />
            </Col>
          </Col>
        </TabPane>
      </TabBar>

      {/* 详情 */}
      <DetailModal
        consultationId={consultationId}
        visible={detailIsModalVisible}
        onOk={() => {
          setDetailIsModalVisible(false)
          setConsultationId('')
        }}
        onCancel={() => {
          setDetailIsModalVisible(false)
          setConsultationId('')
        }}
      />
    </>
  )
}

export const OnlineReviewList = forwardRef(RealOnlineReviewLists)

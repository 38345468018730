import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface UserManagementPrams {
  current: number
  size: number
}

// 列表
export function userManagementList(body: UserManagementPrams): Request {
  return {
    url: `${API_PREFIX}/openUser/selectRegisterOpenUser`,
    method: 'POST',
    body,
  }
}


// 详情
export function userManagementDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/target`,
    method: 'GET',
    params: { id },
  }
}

export interface UserManagementEditPrams {
  isEmployee?: string
  name?: string
  id?: string
  isManager?: any
  departmentId?:any
}

// 保存
export function userManagementEditSubmit(
  body: UserManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/openUser/update`,
    method: 'POST',
    body,
  }
}


export function getTenantDoctors(params: { search?: any }): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/getTenantDoctors`,
    method: 'GET',
    params,
  }
}

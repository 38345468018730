/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 16:51:03
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface OnLineAuctionPartyListPrams {
  current: number
  size: number
  name?: any
  departmentId?: any
  reviewDoctorId?: any
  applicationEndTime?: any
  applicationStartTime?: any
  prescribedEndTime?: any
  prescribedStartTime?: any
}

// 经典方剂列表
export function OnLineAuctionPartyList(
  params: OnLineAuctionPartyListPrams
): Request {
  return {
    url: `${API_PREFIX}/treatment/list`,
    method: 'GET',
    params
  }
}


// 经典方剂在线续方详情
export interface detailPrams {
  treatmentId?: any
  recipeId?: any
}

export function detail(
  params: detailPrams
): Request {
  return {
    url: `${API_PREFIX}/treatment/detail`,
    method: 'GET',
    params
  }
}


// 经典方剂在线续方详情
export interface reviewPrams {
  treatmentId?: any
  review?: any
  refuseCause?: any
}

export function reviewAsync(
  params: reviewPrams
): Request {
  return {
    url: `${API_PREFIX}/treatment/review`,
    method: 'GET',
    params
  }
}

export function doctorList(
): Request {
  return {
    url: `${API_PREFIX}/user/userList`,
    method: 'GET',
  }
}


export interface sendPrams {
  treatmentId?: any
}

export function sendAsync(
  params: sendPrams
): Request {
  return {
    url: `${API_PREFIX}/treatment/sendNotice`,
    method: 'GET',
    params
  }
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-07-07 09:47:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-07-08 16:04:03
 */
// 统一的时间格式
export const DateTimeFormat = 'YYYY/MM/DD HH:mm:ss'
export const DateTimeFormatSimple = 'YYYY/MM/DD'
export const DateTimeFormatSimpleOnly = 'YYYY-MM-DD'
export const DateTimeFormatMessageSimple = 'YYYY-MM-DD HH:mm:ss'
export const DateTimeFormatTimeSimple = 'YYYY-MM-DD HH:mm'

/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-17 13:42:50
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-20 19:26:08
 */
export enum ReleaseStateCategory {
  DFB = '0',
  YFB = '1',
  YCH = '2',
  YJJ = '3',
  // WXYSKF = '4',
}
// 全部
export const ReleaseStateCategories = [
  ReleaseStateCategory.DFB,
  ReleaseStateCategory.YFB,
  ReleaseStateCategory.YCH,
  ReleaseStateCategory.YJJ,
  // ReleaseStateCategory.WXYSKF,
]
// 0：待审核，1：待开方 2：已开方 3：已拒绝 4：无需医生开方

export function getReleaseStateName(state: ReleaseStateCategory): string {
  switch (state) {
    case '0':
      return '待审核'
    case '1':
      return '待开方'
    case '2':
      return '已开方'
    case '3':
      return '已拒绝'
    // case '4':
    //   return '无需医生开方'
  }
  return ''
}


export enum ReleaseStatusCategory {
  DJZ = '0',
  JZZ = '1',
  YWC = '2',
  YQX = '9',
}
// 全部
export const ReleaseStatusCategories = [
  ReleaseStatusCategory.DJZ,
  ReleaseStatusCategory.JZZ,
  ReleaseStatusCategory.YWC,
  // ReleaseStatusCategory.YQX,
]


export const DHZXReleaseStatusCategories = [
  ReleaseStatusCategory.DJZ,
  ReleaseStatusCategory.YWC,
  // ReleaseStatusCategory.YQX,
]

export const ZXFZReleaseStatusCategories = [
  ReleaseStatusCategory.DJZ,
  ReleaseStatusCategory.JZZ,
  ReleaseStatusCategory.YWC,
]
// /0：带接诊 1接诊中 2已完成 9已取消

export function getStatusName(state: ReleaseStatusCategory): string {
  switch (state) {
    case '0':
      return '待接诊'
    case '1':
      return '接诊中'
    case '2':
      return '已完成'
    case '9':
      return '已取消'
  }
  return ''
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 16:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-09 10:18:14
 */
import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
  getReleaseStateName,
  ReleaseStateCategories,
} from '../../models/onLineAuctionParty'
import { RootDispatch } from '../../app/store'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { getDepartmentManagementList } from '../DepartmentManagement/departmentManagementSlice'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import {
  DateTimeFormatMessageSimple,
  DateTimeFormatSimple,
} from '../../models/datetime'
import moment from 'moment'
import { getDepartmentListData } from '../onlinePrescription/onlinePrescriptionSlice'
import { getDoctorListDataAsync } from './onLineAuctionPartySlice'

export const Query = (props: {
  onValueChange: (queries: any) => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { Option } = Select

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [departments, setDepartments] = useState<any[]>([])

  const [doctorList, setDoctorList] = useState<any>([]) //医生列表

  useEffect(() => {
    getDepartmentList()
    getDoctorList()
  }, [dispatch])

  const getDepartmentList = () => {
    dispatch(getDepartmentListData())
      .then(unwrapResult)
      .then((ds: any) => setDepartments(ds))
      .catch(() => {
        // do nothing
      })
  }

  const getDoctorList = () => {
    dispatch(getDoctorListDataAsync())
      .then(unwrapResult)
      .then((v: any) => {
        setDoctorList(v)
      })
  }

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => {
          props.onValueChange({
            ...vs,
            applicationStartTime: vs?.applicationTimeHead
              ? moment(vs?.applicationTimeHead).format(
                  DateTimeFormatMessageSimple
                )
              : head,
            applicationEndTime: vs?.applicationTimeTail
              ? moment(vs?.applicationTimeTail).format(
                  DateTimeFormatMessageSimple
                )
              : tail,
            // prescribedStartTime: vs?.prescribedTimeHead
            //   ? moment(vs?.prescribedTimeHead).format(
            //       DateTimeFormatMessageSimple
            //     )
            //   : head,
            // prescribedEndTime: vs?.prescribedTimeTail
            //   ? moment(vs?.prescribedTimeTail).format(
            //       DateTimeFormatMessageSimple
            //     )
            //   : tail,
            applicationTimeHead: undefined,
            applicationTimeTail: undefined,
            prescribedTimeHead: undefined,
            prescribedTimeTail: undefined,
          })
          console.log(vs, 'wwwwwwwwww')
        }}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='就诊人姓名/手机号'
                style={{ width: 180 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            {/* <Form.Item noStyle name='departmentId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='科室'
                allowClear
                onChange={form.submit}
              >
                {departments?.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item noStyle name='reviewDoctorId'>
              <Select
                style={{ width: '7rem' }}
                placeholder='医生'
                allowClear
                onChange={form.submit}
              >
                {doctorList?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='申请时间'
              labelPrefix='申请时间-'
              namePrefix='applicationTime'
              style={{ width: '9rem' }}
              initialValue={DateRangeType.ThisMonth}
              onChange={form.submit}
              onlyTime='1'
              allowClear={false}
            />
            {/* <DateSelect
              placeholder='开方时间'
              labelPrefix='开方时间-'
              namePrefix='prescribedTime'
              style={{ width: '9rem' }}
              initialValue={DateRangeType.ThisMonth}
              onChange={form.submit}
              onlyTime='2'
              allowClear={false}
            /> */}
            <Form.Item noStyle name='recipeState'>
              <Select
                placeholder='状态'
                allowClear
                style={{ width: '8rem' }}
                onChange={() => form.submit()}
              >
                {ReleaseStateCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getReleaseStateName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

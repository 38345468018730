/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-17 10:28:42
 */

import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Space,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { typeSettingColumns } from './columns'
import styles from './classicalPrescription.module.css'
import {
  getPrescriptionTypeAddOrEditSubmit,
  getPrescriptionTypeDetail,
} from './classicalPrescriptionSlice'

interface addOrEditTypeSettingModalProps {
  id?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const AddOrEditTypeSettingModal = ({
  id,
  visible,
  onCancel,
  onOk,
}: addOrEditTypeSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  useEffect(() => {
    if (visible && id) {
      dispatch(getPrescriptionTypeDetail({ id: id }))
        .then(unwrapResult)
        .then((v: any) => {
          form.setFieldsValue({
            ...v,
            enumSort:v?.enumSort > 0  ? v?.enumSort :''
          })
        })
    }
  }, [visible])

  return (
    <>
      <Modal
        title={id ? '类型编辑' : '类型新增'}
        width={600}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        onOk={() => {
          onOk()
          form.resetFields()
        }}
        footer={null}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            dispatch(
              getPrescriptionTypeAddOrEditSubmit({
                ...values,
                enumType: 'formula',
                id: id,
              })
            )
              .then(unwrapResult)
              .then((v: any) => {
                notification.success({
                  message: '操作成功',
                })
                onOk()
                form.resetFields()
              })
              .catch(() => {
                onCancel()
                form.resetFields()
              })
          }}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label='类型名称'
                name='enumName'
                required
                rules={[{ required: true, message: '类型名称不能为空' }]}
              >
                <Input style={{width:'300px'}}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label='排序' name='enumSort'>
                <InputNumber style={{width:'300px'}}/>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              width: '600px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              ghost
              type='primary'
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              style={{
                marginLeft: '20px',
              }}
              onClick={() => {
                form.submit()
              }}
            >
              保存
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-17 17:22:59
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

// 列表
export interface ClassicalPrescriptionListPrams {
  current: number
  size: number
  formulaName?: any
  formulaType?: any
  formulaState?: any
  sort?:any
}

export function classicalPrescriptionList(
  params: ClassicalPrescriptionListPrams
): Request {
  return {
    url: `${API_PREFIX}/formula/list`,
    method: 'GET',
    params,
  }
}

// 上架
export interface GroundingParams {
  id?: any
}

export function classicalPrescriptionGrounding(
  params: UndercarriageParams
): Request {
  return {
    url: `${API_PREFIX}/formula/formulaListing`,
    method: 'GET',
    params,
  }
}

// 下架
export interface UndercarriageParams {
  id?: any
}

export function classicalPrescriptionUndercarriage(
  params: UndercarriageParams
): Request {
  return {
    url: `${API_PREFIX}/formula/formulaRemoval`,
    method: 'GET',
    params,
  }
}

// 详情
export function ClassicalPrescriptionDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/formula/detail`,
    method: 'GET',
    params: { id },
  }
}

export interface AddOrEditClassicalPrescriptionPrams {
  knowledgeImg?: string
  type?: string
  title?: string
  id?: string
  knowledgeVideo?: string
  content?: any
}

// 保存
export function addOrEditClassicalPrescriptionSubmit(
  body: AddOrEditClassicalPrescriptionPrams
): Request {
  return {
    url: `${API_PREFIX}/formula/editOrSave`,
    method: 'POST',
    body,
  }
}

// 删除
export interface deleteParams {
  id?: any
}

export function deleteSubmit(params: deleteParams): Request {
  return {
    url: `${API_PREFIX}/formula/delete`,
    method: 'GET',
    params,
  }
}

// oss图片上传
export function classicalPrescriptionOssUrl(file: any): Request {
  return {
    url: `${API_PREFIX}/oss/uploadList`,
    method: 'POST',
    // headers: {
    //   [ContentType]: MultiPartFormData,
    // },
    body: file,
  }
}

// 方剂类型列表
// export interface PrescriptionListPrams {
//   current: number
//   size: number
//   formulaName?: any
//   formulaType?: any
//   formulaState?: any
// }

export function prescriptionTypeList(enumType: any): Request {
  return {
    url: `${API_PREFIX}/enum/list`,
    method: 'GET',
    params: enumType,
  }
}

// 方剂类型保存

export interface prescriptionTypeAddOrEditPrams {
  enumName: any
  enumSort?: any
  id?: any
  enumType?:any
}

export function prescriptionTypeAddOrEditSubmit(
  body: prescriptionTypeAddOrEditPrams
): Request {
  return {
    url: `${API_PREFIX}/enum/editOrSave`,
    method: 'POST',
    body,
  }
}

// 方剂类型详情
export interface prescriptionTypeDetailParams {
  id?: any
}

export function prescriptionTypeDetail(
  params: prescriptionTypeDetailParams
): Request {
  return {
    url: `${API_PREFIX}/enum/detail`,
    method: 'GET',
    params,
  }
}

// 方剂类型删除
export interface prescriptionTypeDeleteParams {
  id?: any
}

export function prescriptionTypeDelete(
  params: prescriptionTypeDeleteParams
): Request {
  return {
    url: `${API_PREFIX}/enum/delete`,
    method: 'GET',
    params,
  }
}

// 预诊问题列表
export interface QuestionListPrams {
  formule_id: number
}

export function questionList(params: QuestionListPrams): Request {
  return {
    url: `${API_PREFIX}/formulaQuestion/list`,
    method: 'GET',
    params,
  }
}

// 预诊问题详情
export interface QuestionDetailPrams {
  id: number
}

export function questionDetail(params: QuestionDetailPrams): Request {
  return {
    url: `${API_PREFIX}/formulaQuestion/detail`,
    method: 'GET',
    params,
  }
}

// 预诊问题编辑保存
export interface QuestionEditSubmitPrams {
  id: number
  questionName?: any
  questionSort?: any
}

export function questionEditSubmitAsync(
  body: QuestionEditSubmitPrams
): Request {
  return {
    url: `${API_PREFIX}/formulaQuestion/editOrSave`,
    method: 'POST',
    body,
  }
}

// 预诊问题删除
export interface questionDeletePrams {
  id?: any
}

export function questionDelete(params: questionDeletePrams): Request {
  return {
    url: `${API_PREFIX}/formulaQuestion/delete`,
    method: 'GET',
    params,
  }
}

// 获取添加的药品
export interface listByComboIdParams {
  comboId?: any
}

export function listByComboId(params: listByComboIdParams): Request {
  return {
    url: `${API_PREFIX}/formulaDetail/listByComboId`,
    method: 'GET',
    params,
  }
}

// 获取方剂
export interface formulaDetailListParams {
  current?: any
  size?: any
}

export function formulaDetailList(params: formulaDetailListParams): Request {
  return {
    url: `${API_PREFIX}/formulaDetail/list`,
    method: 'GET',
    params,
  }
}

// 医生列表
export function docList(): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/getIhHISDoctorList`,
    method: 'GET',
  }
}

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import { DetailModal } from './detailModal'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import { getOnlineConsultantList } from './onlineConsultantSlice'
import { Category } from '../../models/category'

export interface OnlineConsultantListRef {
  refresh?: () => void
  tabKey?: any
}

interface OnlineConsultantListProps {
  tabKey?: any
}
const RealOnlineConsultantLists = (
  { tabKey }: OnlineConsultantListProps,
  ref: React.Ref<OnlineConsultantListRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [detailIsModalVisible, setDetailIsModalVisible] = useState(false)

  const [consultationId, setConsultationId] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getOnlineConsultantList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (tabKey == '0' || tabKey == '1') {
      setParams({
        ...params,
        current: 1,
        size: 10,
        category: tabKey == '0' ? 4 : tabKey == '1' ? 5 : '',
      })
    }
  }, [tabKey])

  useEffect(() => {
    if (params) {
      if (params?.category) {
        getPage()
      }
    }
  }, [params])

  return (
    <>
      <Col
        style={{
          height: 'calc(100% - 30px)',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          margin: ' 10px 0',
          flexDirection: 'column',
        }}
      >
        <Col
          style={{
            flex: 1,
            backgroundColor: theme.pn,
            borderRadius: 10,
            padding: '15px  15px 0 15px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Query
          tabKey={tabKey}
            onValueChange={(v: any) => {
              setParams({
                ...params,
                current: 1,
                ...v,
              })
            }}
          />
          <EditableList
            rowKey={(_, i) => `${i}}`}
            page={{
              items: page?.items,
              current: params?.current,
              size: params?.size,
              total: page?.total,
            }}
            loading={pageLoading}
            columns={columns(
              (action: any, v: any) => {
                switch (action) {
                  case 'detail':
                    setConsultationId(v?.consultationId)
                    setDetailIsModalVisible(true)
                    break
                }
              },
              params?.size * (params?.current - 1),
              tabKey
            )}
            onChangePage={(current, size) => {
              setParams({ ...params, current, size: size || 10 })
            }}
          />
        </Col>
      </Col>

      {/* 详情 */}
      <DetailModal
        consultationId={consultationId}
        visible={detailIsModalVisible}
        onOk={() => {
          setDetailIsModalVisible(false)
          setConsultationId('')
        }}
        onCancel={() => {
          setDetailIsModalVisible(false)
          setConsultationId('')
        }}
      />
    </>
  )
}

export const OnlineConsultantList = forwardRef(RealOnlineConsultantLists)

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-14 14:10:32
 */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getDepartCountWithUserList } from '../divisionManagement/divisionManagementSlice'
import styles from './userManagement.module.css'
import {
  getTenantDoctorsAsync,
  getUserManagementEditSubmit,
} from './userManagementSlice'

interface editDetailModalProps {
  detail?: any
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditUserDetailModal = ({
  detail,
  id,
  visible,
  onCancel,
  onOk,
}: editDetailModalProps): ReactElement => {
  const { Option } = Select

  const [form] = Form.useForm()

  const [names, setNames] = useState<any>('')

  const [departmentList, setDepartmentList] = useState<any>()

  const dispatch = useDispatch<RootDispatch>()

  const [radioType, setRadioType] = useState(0)

  const [isDoctorFlag, setIsDoctorFlag] = useState<any>(1)

  const [doctorList, setDoctorList] = useState<any>([]) //医生列表

  useEffect(() => {
    if (visible) {
      if (detail)
        form.setFieldsValue({
          ...detail,
          name: detail?.name,
          departmentId: detail?.departmentId > 0 ? detail?.departmentId : '',
        })
      setRadioType(detail?.isEmployee)
      setIsDoctorFlag(detail?.isDoctor)
    }else{
      setNames('')
    }
  }, [visible, detail])

  useEffect(() => {
    if (visible) {
      dispatch(getDepartCountWithUserList(''))
        .then(unwrapResult)
        .then((v) => {
          setDepartmentList(v)
        })
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      dispatch(getTenantDoctorsAsync({ search: names ? names : undefined }))
        .then(unwrapResult)
        .then((v: any) => {
          setDoctorList(v)
        })
    }
  }, [visible, names])

  return (
    <Modal
      title='用户账号编辑'
      width={600}
      visible={visible}
      // destroyOnClose
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          console.log(values, '111111111111')
          dispatch(
            getUserManagementEditSubmit({
              ...detail,
              id: id,
              ...values,
              nickname: undefined,
              patientPhonenumber: undefined,
            })
          )
            .then(unwrapResult)
            .then((v: any) => {
              notification.success({
                message: '操作成功',
                duration: 3,
              })
              onOk()
              form.resetFields()
            })
        }}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Form.Item label='账号' name='patientPhonenumber'>
          <Input style={{ width: '300px' }} disabled={true} />
        </Form.Item>
        <Form.Item
          name='isEmployee'
          label='是否员工'
          required
          rules={[{ required: true, message: '类型不能为空' }]}
        >
          <Radio.Group
            defaultValue={0}
            value={radioType}
            onChange={(e) => {
              setRadioType(e?.target?.value)
            }}
          >
            <Radio value={1}>是</Radio>
            <Radio value={0} style={{ marginLeft: '20px' }}>
              否
            </Radio>
          </Radio.Group>
        </Form.Item>
        {radioType == 1 && (
          <>
            <Form.Item
              label='用户姓名'
              name='name'
              required
              rules={[{ required: true, message: '用户姓名不能为空' }]}
            >
              <Input style={{ width: '300px' }} />
            </Form.Item>
            <Form.Item
              label='所在部门'
              name='departmentId'
              required
              rules={[{ required: true, message: '所在部门不能为空' }]}
            >
              <Select placeholder='所在部门' allowClear style={{ width: 140 }}>
                {departmentList?.map((v: any) => {
                  return (
                    <Option value={v?.id} key={v?.id}>
                      {v?.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name='isManager'
              label='是否主管'
              required
              rules={[{ required: true, message: '是否主管不能为空' }]}
            >
              <Radio.Group defaultValue={1}>
                <Radio value={1}>是</Radio>
                <Radio value={0} style={{ marginLeft: '20px' }}>
                  否
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name='isDoctor'
              label='是否医生'
              required
              rules={[{ required: true, message: '是否主管不能为空' }]}
            >
              <Radio.Group
                defaultValue={0}
                onChange={(e) => {
                  setIsDoctorFlag(e?.target?.value)
                }}
              >
                <Radio value={1}>是</Radio>
                <Radio value={0} style={{ marginLeft: '20px' }}>
                  否
                </Radio>
              </Radio.Group>
            </Form.Item>
            {isDoctorFlag == 1 ? (
              <Form.Item
                label='医生'
                name='doctorId'
                required
                rules={[{ required: true, message: '医生不能为空' }]}
              >
                <Select
                  showSearch
                  filterOption={false}
                  placeholder='请选择医生'
                  allowClear
                  style={{ width: 140 }}
                  onSearch={(v) => {
                    setNames(v)
                  }}
                >
                  {doctorList?.map((v: any) => {
                    return (
                      <Option value={v?.id} key={v?.id}>
                        {v?.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            ) : (
              ''
            )}
          </>
        )}

        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Space>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}

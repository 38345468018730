/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-17 14:34:12
 */

import { CaretUpFilled } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Space,
  Spin,
  Table,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './classicalPrescription.module.css'
import {
  getFormulaDetailListAsync,
  getListByComboIdAsync,
} from './classicalPrescriptionSlice'

interface addPrescriptionModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
  addMedical: (v: any, n: any) => void
}
export const AddPrescriptionModal = ({
  visible,
  onCancel,
  onOk,
  addMedical,
}: addPrescriptionModalProps): ReactElement => {
  const { Panel } = Collapse

  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [list, setList] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [total, setTotal] = useState<any>()

  const [pageLoading, setPageLoading] = useState<any>()

  useEffect(() => {
    if (visible) {
      setPageLoading(true)
      dispatch(
        getFormulaDetailListAsync({
          current: params.current,
          size: params.size,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setList(v?.records)
          setTotal(v?.total)
          setPageLoading(false)
        })
        .catch(() => {
          setPageLoading(false)
        })
    }
  }, [visible, params])

  return (
    <>
      <Modal
        className={styles.addPrescriptionModal}
        title='添加方剂'
        width={800}
        footer={null}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
          setList([])
          setParams({
            current: 1,
            size: 10,
          })
        }}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
        style={{ position: 'relative' }}
      >
        <Spin
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            zIndex: 99,
          }}
          spinning={pageLoading}
          indicator={undefined}
          tip=''
        ></Spin>
        <div style={{ height: '550px', overflow: 'auto' }}>
          {list?.length &&
            list?.map((n: any, index: any) => (
              <Collapse
                key={n?.comboId}
                collapsible='header'
                defaultActiveKey={''}
                expandIcon={({ isActive }) => (
                  <CaretUpFilled
                    rotate={isActive ? 0 : 180}
                    style={{ marginBottom: '10px', color: '#666666' }}
                  />
                )}
                expandIconPosition='right'
              >
                <>
                  <Panel
                    style={{ marginBottom: '10px' }}
                    key={index}
                    header={
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: '100%',
                        }}
                        onClick={(e) => {
                          dispatch(
                            getListByComboIdAsync({ comboId: n?.comboId })
                          )
                            .then(unwrapResult)
                            .then((v: any) => {
                              const data = list?.map((n: any) => {
                                return {
                                  ...n,
                                  items:
                                    n?.comboId == v?.comboId
                                      ? v?.items
                                      : n?.items,
                                }
                              })
                              setList(data)
                            })
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            width: '90%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <span style={{ border: 'none', marginRight: '5px' }}>
                            {n?.comboName}
                          </span>
                        </div>
                        <div
                          style={{
                            marginLeft: '10px',
                            width: '55px',
                            height: '24px',
                            background: '#07C160',
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            dispatch(
                              getListByComboIdAsync({ comboId: n?.comboId })
                            )
                              .then(unwrapResult)
                              .then((v: any) => {
                                addMedical && addMedical(v?.items, n?.comboName)
                                onOk && onOk()
                              })
                          }}
                        >
                          添加
                        </div>
                      </Row>
                    }
                  >
                    <Table
                      pagination={false}
                      size='small'
                      bordered
                      dataSource={n?.items}
                      rowKey={(t: any) => t.id}
                      columns={[
                        {
                          title: '序号',
                          key: 'order',
                          align: 'center',
                          render: (_1, _2, index) => `${index + 1}`,
                        },
                        {
                          title: '药品名称',
                          dataIndex: 'itemName',
                          align: 'center',
                          key: 'itemName',
                          ellipsis: true,
                        },
                        {
                          title: '每帖克数',
                          dataIndex: 'count',
                          align: 'center',
                          key: 'count',
                          ellipsis: true,
                        },
                        {
                          title: '用法',
                          dataIndex: 'normalUseMethod',
                          align: 'center',
                          key: 'normalUseMethod',
                          ellipsis: true,
                        },
                      ]}
                    ></Table>
                  </Panel>
                </>
              </Collapse>
            ))}
        </div>
        <Row
          justify='end'
          align='middle'
          style={{
            height: '60px',
            padding: '0 15px',
          }}
        >
          <Pagination
            showQuickJumper={false}
            showSizeChanger={true}
            pageSize={params?.size}
            current={params.current}
            total={total}
            pageSizeOptions={['10', '20', '50', '100', '500']}
            onChange={(current, size) => {
              setParams({ ...params, current, size: size || 10 })
            }}
            // className={styles.pagination}
            showTotal={(total: any) => `共 ${total} 条`}
          />
        </Row>
      </Modal>
    </>
  )
}

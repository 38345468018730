/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-18 14:16:09
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/onlinePrescription'

// 在线续方列表
export const getOnlinePrescriptionList = createAsyncThunk<
  void,
  DT.OnLinePrescriptionListPrams,
  RootThunkApi<void>
>('onlinePrescription/getOnlinePrescriptionList', async (params, api) => {
  return sendAsync(DT.onlinePrescriptionList(params), api)
})

export const getDepartmentListData = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('clinic/getDepartmentManagementList', async (params, api) => {
  return sendAsync(DT.departmentManagementList(), api)
})

// 在线续方上传
export const getOnlinePrescriptionUpload = createAsyncThunk<
  void,
  DT.OnLinePrescriptionUploadPrams,
  RootThunkApi<void>
>('onlinePrescription/getOnlinePrescriptionUpload', async (params, api) => {
  return sendAsync(DT.onlinePrescriptionUpload(params), api)
})

// 获取厂商
export const getPotionSupplierAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('onlinePrescription/getPotionSupplier', async (params, api) => {
  return sendAsync(DT.getPotionSupplier(), api)
})

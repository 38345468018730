/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-27 14:49:05
 */

import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import { Button, Form, Input, Modal, Radio, Row, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'

interface decoctingSettingModalProps {
  decoctingSettingDetail?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
  decotingModalValues: (v: any) => void
}
export const DecoctingSettingModal = ({
  decoctingSettingDetail,
  visible,
  onCancel,
  onOk,
  decotingModalValues,
}: decoctingSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  useEffect(() => {
    if (visible) {
      if (JSON.stringify(decoctingSettingDetail) != '{}') {
        form.setFieldsValue({
          ...decoctingSettingDetail,
          potionWay:
            decoctingSettingDetail?.potionWay != -1
              ? Number(decoctingSettingDetail?.potionWay) || 1
              : 1,
        })
      } else {
        form.setFieldsValue({
          potionWay: 1,
        })
      }
    }
  }, [visible, decoctingSettingDetail])

  return (
    <>
      <Modal
        title='代煎设置'
        width={600}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        onOk={() => {
          onOk()
          form.resetFields()
        }}
        footer={null}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            console.log(values,"values");
            decotingModalValues(values)
            onOk()
            form.resetFields()
          }}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <div style={{ height: '220px' }}>
            <Form.Item
              label='加工方式'
              name='potionWay'
              rules={[{ required: true, message: '加工方式不能为空' }]}
            >
              <Radio.Group >
                <Radio value={2}>自煎</Radio>
                <Radio value={1}>代煎</Radio>
                {/* <Radio value={3}>膏方代煎</Radio> */}
                <Radio value={7}>制丸</Radio>
                {/* <Radio value={8}>打粉</Radio> */}
              </Radio.Group>
            </Form.Item>
            <Form.Item label='包装规格' name='potionNorm'>
              <Input style={{ width: '300px' }} placeholder='请输入' />
            </Form.Item>
            <Form.Item label='备注' name='potionNote'>
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ width: '300px' }}
                placeholder='请输入'
              />
            </Form.Item>
          </div>
          <Row
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              type='primary'
              ghost
              style={{
                color: '#999',
                borderColor: '#999',
              }}
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              style={{
                marginLeft: '20px',
              }}
              onClick={() => {
                form.submit()
              }}
            >
              保存
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 14:10:35
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-17 09:59:57
 */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './onlineConsultant.module.css'
import { OnlineConsultantList } from './onlineConsultantList'

export const OnlineConsultant = (): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [tabKey, setTabKey] = useState('0')

  return (
    <>
      <TabBar
        style={{ margin: '10px 20px' }}
        onChange={(v) => {
          setTabKey(v)
        }}
      >
        <TabPane key='0' tab='图文咨询'>
          <div className={styles.container}>
            <OnlineConsultantList tabKey={tabKey == '0' ?tabKey :''}></OnlineConsultantList>
          </div>
        </TabPane>
        <TabPane key='1' tab='电话咨询'>
          <div className={styles.container}>
            <OnlineConsultantList tabKey={tabKey == '1' ?tabKey :''}></OnlineConsultantList>
          </div>
        </TabPane>
      </TabBar>
    </>
  )
}

import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { DateTimeFormatTimeSimple } from '../../models/datetime'
import {
  getOrderStateName,
  getReleaseStateName,
} from '../../models/orderManagemnet'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

export const columns = (
  onClick: (action: any, v?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'age',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.age ? r?.age : '-'
      },
    },
    {
      title: '身份证号',
      dataIndex: 'idCard',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'idCard',
      render: (_, r) => {
        return r?.idCard ? r?.idCard : '-'
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '类型',
      dataIndex: 'orderType',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.orderType ? getReleaseStateName(r.orderType?.toString()) : '-'
      },
    },
    {
      title: '开方医生',
      dataIndex: 'type',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.treatmentDoctorName ? r.treatmentDoctorName : '-'
      },
    },
    {
      title: '科室',
      dataIndex: 'type',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return r.treatmentDepartmentName ? r.treatmentDepartmentName : '-'
      },
    },
    {
      title: '开方时间',
      dataIndex: 'prescribedTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.prescribedTime
          ? moment(r.prescribedTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '支付时间',
      dataIndex: 'payTime',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.payTime
          ? moment(r.payTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '结算状态',
      dataIndex: 'state',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.state?.toString()
          ? getOrderStateName(r?.state?.toString())
          : '-'
      },
    },
    {
      title: '上传状态',
      dataIndex: 'recipeUploadStatus',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.recipeUploadStatus?.toString() == '0'
          ? '待上传'
          : r.recipeUploadStatus?.toString() == '1'
          ? '已上传'
          : '-'
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '7rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('detail', u)}
              style={{ marginRight: '10px' }}
            >
              详情
            </a>
            {(u.recipeUploadStatus?.toString() == '0'  && u?.state?.toString() == '1' )? (
              <a onClick={() => onClick('upload', u)}>上传</a>
            ) : ( 
              <></> 
          )} 
          </>
        )
      },
    },
  ]
}

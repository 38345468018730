/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 16:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-16 17:15:37
 */
import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { freePatientQueryParams } from '../../services/freePatients'
import {
  getReleaseStatusName,
  getReleaseTypeName,
  ReleaseStatusCategories,
  ReleaseTypeCategories,
} from '../../models/classicalPrescription'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getPrescriptionTypeListAsync } from './classicalPrescriptionSlice'

export const Query = (props: {
  onValueChange: (queries: any) => void
  addPrescription: () => void
}): ReactElement => {
  const [form] = Form.useForm()

  const { Option } = Select

  const dispatch = useDispatch<RootDispatch>()

  const [prescriptionTypeList, setPrescriptionTypeList] = useState<any>() //方剂类型

  useEffect(() => {
    getPrescriptionList()
  }, [])

  const getPrescriptionList = () => {
    dispatch(getPrescriptionTypeListAsync({enumType:'formula'}))
      .then(unwrapResult)
      .then((v) => {
        setPrescriptionTypeList(v)
      })
  }

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='formulaName' noStyle>
              <Input
                autoComplete='off'
                placeholder='方剂名称'
                style={{ width: '10rem' }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='formulaType'>
              <Select
                placeholder='类型'
                allowClear
                style={{ width: '8rem' }}
                onChange={() => form.submit()}
              >
                {prescriptionTypeList?.map((v: any) => {
                  return (
                    <Option value={v?.id} key={v?.id}>
                      {v?.enumName}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='formulaState'>
              <Select
                placeholder='状态'
                allowClear
                style={{ width: '8rem' }}
                onChange={() => form.submit()}
              >
                {ReleaseStatusCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getReleaseStatusName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                props?.addPrescription && props?.addPrescription()
              }}
            >
              新增方剂
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

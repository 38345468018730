import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Space,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './classicalPrescription.module.css'
import {
  getQuestionDetailAsync,
  getQuestionEditSubmitAsync,
} from './classicalPrescriptionSlice'

interface addOrEditBriefDescriptionModalProps {
  detail?:any
  visible: boolean
  onCancel: () => void
  onOk: () => void
  onValueChange:(v:any) =>void
}
export const BriefDescriptionModal = ({
  detail,
  visible,
  onCancel,
  onOk,
  onValueChange
}: addOrEditBriefDescriptionModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()


  useEffect(() => {
   if(visible){
    form.setFieldsValue({
      synopsis:detail?.synopsis,
      explanation:detail?.explanation
    })
   }else{
    form.resetFields()
   }
  }, [visible])
  
  return (
    <>
      <Modal
        title={'简介说明'}
        width={600}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        onOk={() => {
          onOk()
          form.resetFields()
        }}
        footer={null}
        centered
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            onValueChange &&  onValueChange(values)
          }}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label='简介'
                name='synopsis'
                required
                rules={[{ required: true, message: '简介不能为空' }]}
              >
                <TextArea placeholder='请输入' showCount maxLength={100}/>
              </Form.Item>
            </Col>
          </Row>
        
          <Row>
            <Col span={24}>
              <Form.Item
                label='说明'
                name='explanation'
                rules={[{ required: true, message: '说明不能为空' }]}
              >
                 <TextArea placeholder='请输入' showCount maxLength={100}/>
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.buttonMain}>
            <Button
              type='primary'
              ghost
              style={{
                color: '#999',
                borderColor: '#999',
              }}
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              style={{
                marginLeft: '20px',
              }}
              onClick={() => {
                form.submit()
              }}
            >
              确认
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

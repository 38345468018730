import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  notification,
  Row,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './column'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import styles from './onlinePrescription.module.css'
import { TableRowSelection } from 'antd/lib/table/interface'
import {
  getOnlinePrescriptionList,
  getOnlinePrescriptionUpload,
} from './onlinePrescriptionSlice'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import moment from 'moment'
import {
  DateTimeFormatMessageSimple,
  DateTimeFormatSimpleOnly,
} from '../../models/datetime'
import { OnlinePrescriptionDetailModal } from './onlinePrescriptionDetailModal'
import { PrescriptionUploadModal } from './PrescriptionUploadModal'

export interface OrderRef {
  refresh?: () => void
  tabKey?: any
}

interface OrderProps {
  tabKey?: any
}
const OnlinePrescription = (
  { tabKey }: OrderProps,
  ref: React.Ref<OrderRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const history: any = useHistory()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [buttonLoading, setButtonLoading] = useState<any>(false)

  const [visible, setVisible] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState<any>()

  const [selectIds, setSelectIds] = useState<any[]>()

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [recipeId, setRecipeId] = useState<any>()

  const [treatmentId, setTreatmentId] = useState<any>()

  const [potionWay, setPotionWay] = useState<any>()

  const [flag, setFlag] = useState<any>(false)

  const [sPLUpload, setPLUpload] = useState(false)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [selectLists, setSelectLists] = useState<any>()

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectLists(selectedRows)
      setSelectIds(selectedRowKeys)
      console.log(selectedRows, selectedRowKeys, 'selectedRows')
    },
    getCheckboxProps: (v: any) => ({
      disabled: v?.recipeUploadStatus == '1' ? true : false,
    }),
  }

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getOnlinePrescriptionList({
        ...params,
        current: params.current,
        size: params.size,
        prescribedStartTime: params?.prescribedStartTime
          ? moment(params?.prescribedStartTime).format(
              DateTimeFormatMessageSimple
            )
          : moment(head).format(DateTimeFormatMessageSimple),
        prescribedEndTime: params?.prescribedEndTime
          ? moment(params?.prescribedEndTime).format(
              DateTimeFormatMessageSimple
            )
          : moment(tail).format(DateTimeFormatMessageSimple),
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params) {
      getPage()
    }
  }, [params])

  const getUpload = (
    recipeId?: any,
    treatmentId?: any,
    index?: any,
    v?: any
  ) => {
    setPageLoading(true)
    dispatch(
      getOnlinePrescriptionUpload({
        recipeId: recipeId,
        treatmentId: treatmentId,
        supplierId: v?.supplierId ? v?.supplierId : undefined,
        specification: v?.specification ? v?.specification : undefined,
        remark: v?.remark ? v?.remark : undefined,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        if (selectIds?.length) {
          if (index + 1 == selectIds?.length) {
            getPage()
            setSelectIds([])
            setSelectLists([])
            setPageLoading(false)
            setButtonLoading(false)
            setIsModalVisible(false)
            setFlag(false)
          }
        } else {
          getPage()
          setPageLoading(false)
          setButtonLoading(false)
          setIsModalVisible(false)
          setFlag(false)
        }
      })
      .catch(() => {
        getPage()
        setSelectIds([])
        setSelectLists([])
        setPageLoading(false)
        setButtonLoading(false)
        setIsModalVisible(false)
        setFlag(false)
      })
  }

  return (
    <>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='在线续方'>
          <Col
            style={{
              height: 'calc(100% - 30px)',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              margin: ' 10px 0',
              flexDirection: 'column',
            }}
          >
            <Col
              style={{
                flex: 1,
                backgroundColor: theme.pn,
                borderRadius: 10,
                padding: '15px  15px 0 15px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Query
                onValueChange={(v: any) => {
                  setParams({
                    ...params,
                    current: 1,
                    ...v,
                  })
                  setSelectIds([])
                  setSelectLists([])
                }}
                allUpload={() => {
                  setFlag(true)
                  setPLUpload(true)
                  setIsModalVisible(true)
                }}
                buttonLoading={buttonLoading}
              />
              <EditableList
                rowKey={(r) => r.recipeId}
                page={{
                  items: page?.items,
                  current: params.current,
                  size: params.size,
                  total: page.total,
                }}
                rowSelection={
                  {
                    selectedRowKeys: selectIds,
                    ...rowSelection,
                  } as TableRowSelection<any>
                }
                loading={pageLoading}
                columns={columns((action: any, v: any) => {
                  switch (action) {
                    case 'detail':
                      setVisible(true)
                      setRecipeId(v?.recipeId)
                      setTreatmentId(v?.treatmentId)
                      break
                    case 'upload':
                      setPLUpload(false)
                      setIsModalVisible(true)
                      setRecipeId(v?.recipeId)
                      setTreatmentId(v?.treatmentId)
                      setPotionWay(v?.potionWay)
                      // getUpload(v?.recipeId, v?.treatmentId, 1)
                      break
                  }
                }, params.size * (params.current - 1))}
                onChangePage={(current, size) => {
                  setParams({ ...params, current, size: size || 10 })
                }}
              />
            </Col>
          </Col>
        </TabPane>
      </TabBar>

      <OnlinePrescriptionDetailModal
        treatmentId={treatmentId}
        recipeId={recipeId}
        visible={visible}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      ></OnlinePrescriptionDetailModal>
      <PrescriptionUploadModal
        potionWay={potionWay}
        flag={sPLUpload}
        treatmentId={treatmentId}
        recipeId={recipeId}
        visible={isModalVisible}
        onOk={(v) => {
          setPotionWay('')
          if (flag) {
            if (!selectIds?.length) {
              notification.error({
                message: '请至少选择一条未上传的进行上传',
              })
              setButtonLoading(false)
              setIsModalVisible(false)
              return
            } else {
              selectLists?.map((v: any, index: any) => {
                setButtonLoading(true)
                getUpload(v?.recipeId, v?.treatmentId, index, v)
              })
            }
          } else {
            getUpload(recipeId, treatmentId, 1, v)
          }
        }}
        onCancel={() => {
          setPotionWay('')
          setIsModalVisible(false)
        }}
      ></PrescriptionUploadModal>
    </>
  )
}

export const OnlinePrescriptionList = forwardRef(OnlinePrescription)

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:02:20
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 09:37:52
 */


import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/userManagement'
import { getTenantDoctors } from '../../services/onlineConsultant'

// 列表
export const getUserManagementList = createAsyncThunk<
  void,
  DT.UserManagementPrams,
  RootThunkApi<void>
>('clinic/getHealthList', async (params, api) => {
  return sendAsync(DT.userManagementList(params), api)
})


// 详情
export const getUserManagementDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getUserManagementDetail', async (params, api) => {
  return sendAsync(DT.userManagementDetail(params), api)
})

// 保存
export const getUserManagementEditSubmit = createAsyncThunk<
  void,
  DT.UserManagementEditPrams,
  RootThunkApi<void>
>('clinic/getUserManagementEditSubmit', async (params, api) => {
  return sendAsync(DT.userManagementEditSubmit(params), api)
})


export const getTenantDoctorsAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('onlineConsultant/getTenantDoctorsAsync', async (params, api) => {
  return sendAsync(DT.getTenantDoctors(params), api)
})
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-20 17:20:46
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormatTimeSimple } from '../../models/datetime'
import { getReleaseStateName, getStatusName } from '../../models/onLineAuctionParty'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

type ActionType = 'detail'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()

  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.patientName ? r.patientName : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'age',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.age + '岁'
      },
    },
    {
      title: '手机号',
      dataIndex: 'patientPhone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.patientPhone ? r.patientPhone : '-'
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'identityCard',
      render: (_, r) => {
        return r?.identityCard ? r?.identityCard : '-'
      },
    },
    {
      title: '疾病',
      dataIndex: 'confirmatoryDiagnosis',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return  r?.confirmatoryDiagnosis || '-'
      },
    },
    {
      title: '预约医生',
      dataIndex: 'doctorName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.doctorName ? r?.doctorName : '-'
      },
    },
    {
      title: '预约科室',
      dataIndex: 'departmentName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.departmentName ? r?.departmentName : '-'
      },
    },
    {
      title: '接诊时间',
      dataIndex: 'consultationStartTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.consultationStartTime
          ? moment(r.consultationStartTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '预约时间',
      dataIndex: 'appointmentTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.appointmentTime
          ? moment(r.appointmentTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return getStatusName(r?.status?.toString())
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            <a
              onClick={(e) => {
                onClick('detail', t)
              }}
            >
              查看详情
            </a>
              
          </>
        )
      },
    },
  ]
}

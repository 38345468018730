/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 16:50:06
 */


import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/onLineAuctionParty'

// 经典方剂列表
export const getOnLineAuctionPartyList = createAsyncThunk<
 void,
 DT.OnLineAuctionPartyListPrams,
 RootThunkApi<void>
>('onLineAuctionParty/getOnLineAuctionPartyList', async (params, api) => {
 return sendAsync(DT.OnLineAuctionPartyList(params), api)
})

// 经典方剂在线续方详情
export const getDetailAsync = createAsyncThunk<
 void,
 DT.detailPrams,
 RootThunkApi<void>
>('onLineAuctionParty/getDetail', async (params, api) => {
 return sendAsync(DT.detail(params), api)
})

// 去审核
export const getReviewAsync = createAsyncThunk<
 void,
 DT.reviewPrams,
 RootThunkApi<void>
>('onLineAuctionParty/getReviewAsync', async (params, api) => {
 return sendAsync(DT.reviewAsync(params), api)
})

// 拍方医生列表
export const getDoctorListDataAsync = createAsyncThunk<
any,
void,
RootThunkApi<void>
>('classicalPrescription/getFormulaDetailListAsync', async (params, api) => {
return sendAsync(DT.doctorList(), api)
})

// 发送重新发送
export const getSendAsync = createAsyncThunk<
 void,
 DT.sendPrams,
 RootThunkApi<void>
>('onLineAuctionParty/send', async (params, api) => {
 return sendAsync(DT.sendAsync(params), api)
})
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import {
  getClassicalPrescriptionUndercarriage,
  getDeleteSubmit,
} from '../classicalPrescription/classicalPrescriptionSlice'
import {
  getOnLineAuctionPartyList,
  getSendAsync,
} from './onLineAuctionPartySlice'
import { GoToAuditModal } from './goToAuditModal'
import { ExtractionOfARootModal } from './extractionOfARootModal'
import { DetailModal } from './detailModal'
import { setErrorState } from '../../app/applicationSlice'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import { DateTimeFormatMessageSimple } from '../../models/datetime'
import moment from 'moment'

export interface OnLineAuctionPartyRef {
  refresh?: () => void
  tabKey?: any
}

interface OnLineAuctionPartyProps {
  tabKey?: any
}
const RealOnLineAuctionPartys = (
  { tabKey }: OnLineAuctionPartyProps,
  ref: React.Ref<OnLineAuctionPartyRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const history: any = useHistory()

  const [form] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [detailIsModalVisible, setDetailIsModalVisible] = useState(false)

  const [goToAuditIsModalVisible, setGoToAuditIsModalVisible] = useState(false)

  const [
    extractionOfARootIsModalVisible,
    setExtractionOfARootIsModalVisible,
  ] = useState(false)

  const [billingCategory, setBillingCategory] = useState('')

  const [treatmentId, setTreatmentId] = useState<any>()

  const [recipeState, setRecipeState] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getOnLineAuctionPartyList({
        ...params,
        orderType: 2,
        current: params.current,
        size: params.size,
        applicationStartTime: params?.applicationStartTime
          ? moment(params?.applicationStartTime).format(
              DateTimeFormatMessageSimple
            )
          : moment(head).format(DateTimeFormatMessageSimple),
        applicationEndTime: params?.applicationEndTime
          ? moment(params?.applicationEndTime).format(
              DateTimeFormatMessageSimple
            )
          : moment(tail).format(DateTimeFormatMessageSimple),
        // prescribedStartTime: params?.prescribedStartTime
        //   ? moment(params?.prescribedTimeHead).format(
        //       DateTimeFormatMessageSimple
        //     )
        //   : moment(head).format(DateTimeFormatMessageSimple),
        // prescribedEndTime: params?.prescribedTimeTail
        //   ? moment(params?.prescribedTimeTail).format(
        //       DateTimeFormatMessageSimple
        //     )
        //   : moment(tail).format(DateTimeFormatMessageSimple),
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params) {
      getPage()
    }
  }, [params])

  return (
    <>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='在线拍方'>
          <Col
            style={{
              height: 'calc(100% - 30px)',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              margin: ' 10px 0',
              flexDirection: 'column',
            }}
          >
            <Col
              style={{
                flex: 1,
                backgroundColor: theme.pn,
                borderRadius: 10,
                padding: '15px  15px 0 15px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Query
                onValueChange={(v: any) => {
                  setParams({
                    ...params,
                    current: 1,
                    ...v,
                  })
                }}
              />
              <EditableList
                rowKey={(_, i) => `${i}}`}
                page={{
                  items: page.items,
                  current: params.current,
                  size: params.size,
                  total: page.total,
                }}
                loading={pageLoading}
                columns={columns((action: any, v: any) => {
                  switch (action) {
                    case 'detail':
                      setTreatmentId(v?.treatmentId)
                      setRecipeState(v?.recipeState)
                      setDetailIsModalVisible(true)
                      break
                    case 'toExtractTheRootOf':
                      setBillingCategory(v?.billingCategory)
                      setExtractionOfARootIsModalVisible(true)
                      break
                    case 'goToAudit':
                      setTreatmentId(v?.treatmentId)
                      setGoToAuditIsModalVisible(true)
                      break
                    case 'send':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认发送？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          dispatch(
                            getSendAsync({ treatmentId: v?.treatmentId })
                          )
                            .then(unwrapResult)
                            .then((v) => {
                              notification.success({
                                message: '操作成功',
                              })
                              getPage()
                            })
                        },
                      })
                      break
                  }
                }, params.size * (params.current - 1))}
                onChangePage={(current, size) => {
                  console.log(params, 'change')
                  setParams({ ...params, current, size: size || 10 })
                }}
              />
            </Col>
          </Col>
        </TabPane>
      </TabBar>
      {/* 去审核 */}
      <GoToAuditModal
        treatmentId={treatmentId}
        visible={goToAuditIsModalVisible}
        onOk={() => {
          setGoToAuditIsModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setGoToAuditIsModalVisible(false)
          getPage()
        }}
      ></GoToAuditModal>

      {/* 开方 */}
      {/* <ExtractionOfARootModal
        billingCategory={billingCategory}
        visible={extractionOfARootIsModalVisible}
        onOk={() => {
          setExtractionOfARootIsModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setExtractionOfARootIsModalVisible(false)
          getPage()
        }}
      /> */}

      {/* 详情 */}
      <DetailModal
        recipeState={recipeState}
        treatmentId={treatmentId}
        visible={detailIsModalVisible}
        onOk={() => {
          setDetailIsModalVisible(false)
          setTreatmentId('')
        }}
        onCancel={() => {
          setDetailIsModalVisible(false)
          setTreatmentId('')
        }}
      />
    </>
  )
}

export const OnLineAuctionPartyList = forwardRef(RealOnLineAuctionPartys)

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-18 14:20:35
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface OnLinePrescriptionListPrams {
  current: number
  size: number
  name?: any
  departmentId?: any
  reviewDoctorId?: any
  applicationEndTime?: any
  applicationStartTime?: any
  prescribedEndTime?: any
  prescribedStartTime?: any
}

// 列表
export function onlinePrescriptionList(
  params: OnLinePrescriptionListPrams
): Request {
  return {
    url: `${API_PREFIX}/treatment/listXF`,
    method: 'GET',
    params,
  }
}

export interface OnLinePrescriptionUploadPrams {
  recipeId?: any
  treatmentId?: any
  supplierId?: any
  specification?:any
  remark?:any
}

// 在线续方上传
export function onlinePrescriptionUpload(
  body: OnLinePrescriptionUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/treatment/recipeUpload`,
    method: 'POST',
    body,
  }
}

// 科室列表
export function departmentManagementList(): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/getIhHISDepartmentList`,
    method: 'GET',
  }
}

export function getPotionSupplier(): Request {
  return {
    url: `${API_PREFIX}/treatment/getPotionSupplier`,
    method: 'GET',
  }
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-20 20:32:23
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormatTimeSimple } from '../../models/datetime'
import { getReleaseStateName } from '../../models/onLineAuctionParty'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

type ActionType = 'detail' | 'goToAudit' | 'toExtractTheRootOf' | 'send'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()

  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },

    {
      title: '年龄',
      dataIndex: 'age',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.age + '岁'
      },
    },
    {
      title: '身份证号',
      dataIndex: 'idCard',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'idCard',
      render: (_, r) => {
        return r?.idCard ? r?.idCard : '-'
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '审核医生',
      dataIndex: 'reviewDoctorName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.reviewDoctorName ? r.reviewDoctorName : '-'
      },
    },
    {
      title: '申请时间',
      dataIndex: 'applicationTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.applicationTime
          ? moment(r.applicationTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '开方时间',
      dataIndex: 'prescribedTime',
      align: 'center',
      width: '9rem',
      ellipsis: true,
      render: (_, r) => {
        return r.prescribedTime
          ? moment(r.prescribedTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '状态',
      dataIndex: 'recipeState',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return getReleaseStateName(r?.recipeState?.toString())
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            {t?.recipeState == '0' ? (
              <a
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  onClick('goToAudit', t)
                }}
              >
                去审核
              </a>
            ) : (
              <></>
            )}
            {/* {t?.recipeState == '1' ? (
              <a
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  onClick('toExtractTheRootOf', t)
                }}
              >
                去开方
              </a>
            ) : (
              <></>
            )} */}
            <a
              onClick={(e) => {
                onClick('detail', t)
              }}
            >
              详情
            </a>
            {t?.recipeState == 2 ? (
              <>
                {t?.sendState == 1 ? (
                  <a
                    style={{ marginLeft: 10 }}
                    onClick={(e) => {
                      onClick('send', t)
                    }}
                  >
                    重新发送
                  </a>
                ) : (
                  <a
                    style={{ marginLeft: 10 }}
                    onClick={(e) => {
                      onClick('send', t)
                    }}
                  >
                    发送通知
                  </a>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ]
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-12 14:04:49
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/onlineConsultant'

// 咨询列表
export const getOnlineConsultantList = createAsyncThunk<
  void,
  DT.OnlineConsultantListParams,
  RootThunkApi<void>
>('onlineConsultant/getOnlineConsultantList', async (params, api) => {
  return sendAsync(DT.OnlineConsultantList(params), api)
})

export const getDetailAsync = createAsyncThunk<
  void,
  DT.detailPrams,
  RootThunkApi<void>
>('onlineConsultant/getDetail', async (params, api) => {
  return sendAsync(DT.detail(params), api)
})

export const getTenantDoctorsAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('onlineConsultant/getTenantDoctorsAsync', async (params, api) => {
  return sendAsync(DT.getTenantDoctors(), api)
})

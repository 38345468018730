/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-13 17:09:21
 */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import {
  getReleaseStatusName,
  getReleaseTypeName,
} from '../../models/classicalPrescription'
import { DateTimeFormatTimeSimple } from '../../models/datetime'

type ActionType = 'detail' | 'edit' | 'undercarriage' | 'delete' | 'grounding'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number,
  sortedInfo?: any
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '商品ID',
      dataIndex: 'goodsId',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.goodsId != -1 ? r.goodsId : ''
      },
    },
    {
      title: '方剂名称',
      dataIndex: 'formulaName',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return r.formulaName ? r.formulaName : '-'
      },
    },

    {
      title: '图片',
      dataIndex: 'formulaImg',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function FormulaImg(_, t): ReactElement {
        const imgList = t?.formulaImg?.split('@$$@')?.map((v: any) => {
          return { imgBaseInfo: v }
        })
        return imgList?.[0]?.imgBaseInfo ? (
          <img
            src={imgList?.[0]?.imgBaseInfo}
            style={{ width: '30px', height: '30px' }}
          />
        ) : (
          <></>
        )
      },
    },
    {
      title: '类型',
      dataIndex: 'enumName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      key: 'enumName',
      render: (_, r) => {
        return r.enumName
      },
    },
    {
      title: '起购帖数',
      dataIndex: 'formulaNumber',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return r.formulaNumber ? r.formulaNumber : '-'
      },
    },
    {
      title: '每帖价格',
      dataIndex: 'formulaPrice',
      align: 'right',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return r.formulaPrice ? r.formulaPrice?.toFixed(2) : '-'
      },
    },
    {
      title: '销售次数',
      dataIndex: 'formulaSale',
      key: 'formulaSale',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'formulaSale' && sortedInfo?.order,
      render: (_, r) => {
        return r.formulaSale ? r.formulaSale : '-'
      },
    },
    {
      title: '是否在售',
      dataIndex: 'formulaState',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return getReleaseStatusName(r.formulaState?.toString())
      },
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.updateTime
          ? moment(r.updateTime).format(DateTimeFormatTimeSimple)
          : '-'
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '12rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            {/* <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('detail', t)
              }}
            >
              详情
            </a> */}
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('edit', t)
              }}
            >
              编辑
            </a>
            {t.formulaState == 1 ? (
              <a
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  onClick('undercarriage', t)
                }}
              >
                下架
              </a>
            ) : (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('grounding', t)
                  }}
                >
                  上架
                </a>
                {!t.formulaSale ? (
                  <a
                    onClick={(e) => {
                      onClick('delete', t)
                    }}
                  >
                    删除
                  </a>
                 ) : (
                  ''
                )} 
              </>
            )}
          </>
        )
      },
    },
  ]
}

export const prescriptionColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: '药品名称',
      dataIndex: 'itemName',
      align: 'center',
      width: '14rem',
      ellipsis: true,
      render: (_, r) => {
        return r.itemName ? r.itemName : '-'
      },
    },
    {
      title: '每帖克数',
      dataIndex: 'count',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '用法',
      dataIndex: 'normalUseMethod',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
  ]
}

export const questionColumns = (
  onClick: (action: ActionType, v: any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${index + 1}`,
    },

    {
      title: '问题名称',
      dataIndex: 'questionName',
      align: 'center',
      width: '16rem',
      ellipsis: true,
      render: (_, r) => {
        return r.questionName ? r.questionName : '-'
      },
    },

    {
      title: '排序',
      dataIndex: 'questionSort',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return r.questionSort ? r.questionSort : '-'
      },
    },

    {
      title: '操作',
      key: 'actions',
      width: '6rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('edit', t)
              }}
            >
              编辑
            </a>
            <a
              onClick={(e) => {
                onClick('delete', t)
              }}
            >
              删除
            </a>
          </>
        )
      },
    },
  ]
}

export const typeSettingColumns = (
  onClick: (action: ActionType, v: any, state?: any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${index + 1}`,
    },

    {
      title: '类型名称',
      dataIndex: 'enumName',
      align: 'center',
      width: '16rem',
      ellipsis: true,
      render: (_, r) => {
        return r.enumName ? r.enumName : ''
      },
    },

    {
      title: '排序',
      dataIndex: 'enumSort',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return r.enumSort != -1 ? r.enumSort : ''
      },
    },

    {
      title: '操作',
      key: 'actions',
      width: '6rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('edit', t)
              }}
            >
              编辑
            </a>
            <a
              onClick={(e) => {
                onClick('delete', t)
              }}
            >
              删除
            </a>
          </>
        )
      },
    },
  ]
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-15 09:22:26
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-18 11:45:23
 */
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Image,
  Row,
  Space,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import noData from '../patientsManagement/images/noecharts.jpg'
import styles from './onLineAuctionParty.module.css'
import { getDetailAsync, getReviewAsync } from './onLineAuctionPartySlice'

interface goToAuditModalProps {
  treatmentId?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const GoToAuditModal = ({
  treatmentId,
  visible,
  onCancel,
  onOk,
}: goToAuditModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [detail, setDetail] = useState<any>()

  const [rejectIsModalVisible, setRejectIsModalVisible] = useState<any>()

  const labelStyle = {
    style: {
      textAlign: 'right',
      marginBottom: 10,
      marginRight: '6px',
      width: '90px',
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      marginBottom: 10,
    } as React.CSSProperties,
  }

  useEffect(() => {
    if (visible) {
      dispatch(getDetailAsync({ treatmentId: treatmentId }))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [visible])

  return (
    <>
      <Modal
        title={'审核'}
        width={800}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        centered
        footer={null}
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <div style={{ height: '360px', overflow: 'auto' }}>
          <Row>
            <Col span={8}>
              <Row>
                <Col {...labelStyle}>姓名：</Col>
                <Col {...valueStyle}>{detail?.name || '-'}</Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col {...labelStyle}>性别：</Col>
                <Col {...valueStyle}>{getGenderName(detail?.sex)}</Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col {...labelStyle}>年龄：</Col>
                <Col {...valueStyle}>{detail?.age}岁</Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Row>
                <Col {...labelStyle}>手机号：</Col>
                <Col {...valueStyle}>{detail?.phone}</Col>
              </Row>
            </Col>
            <Col span={16}>
              <Row>
                <Col {...labelStyle}>身份证号：</Col>
                <Col {...valueStyle}>{detail?.idCard}</Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>处方：</Col>
                <Col {...valueStyle}>
                  {detail?.recipePictures ? (
                    detail?.recipePictures?.split(',')?.map((v: any) => {
                      return (
                        <Image
                          src={v}
                          key={v}
                          style={{
                            width: '60px',
                            height: '60px',
                            marginRight: '10px',
                          }}
                        ></Image>
                      )
                    })
                  ) : (
                    <>-</>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>剂型：</Col>
                <Col {...valueStyle}>{detail?.dosageFormk || '-'}</Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>备注：</Col>
                <Col style={{ flex: '1', marginBottom: '10px' }}>
                  {detail?.remark || '-'}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Space>
            <Button
              danger
              style={{ marginRight: '4px' }}
              onClick={() => {
                setRejectIsModalVisible(true)
              }}
            >
              拒绝
            </Button>

            <Button
              type='primary'
              onClick={() => {
                dispatch(
                  getReviewAsync({
                    treatmentId: treatmentId,
                    review: 1,
                  })
                )
                  .then(unwrapResult)
                  .then((v) => {
                    notification.success({
                      message: '操作成功',
                    })
                    onOk()
                  })
              }}
            >
              通过
            </Button>
          </Space>
        </Row>
      </Modal>
      <Modal
        centered
        visible={rejectIsModalVisible}
        title={'确认拒绝'}
        onOk={() => {
          form.validateFields().then((values) => {
            console.log(values, 'values')
            dispatch(
              getReviewAsync({
                treatmentId: treatmentId,
                review: 0,
                refuseCause: values?.refuseCause,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '操作成功',
                })
                onOk()
                form.resetFields()
              })
            setRejectIsModalVisible(false)
          })
        }}
        onCancel={() => {
          setRejectIsModalVisible(false)
          // onCancel()
          form.resetFields()
        }}
        className={styles.rejectModalMain}
      >
        <Form
          form={form}
          onFinish={(values) => {
            return
          }}
        >
          <Form.Item
            name='refuseCause'
            label='拒绝原因'
            required
            rules={[{ required: true, message: '拒绝原因不能为空' }]}
          >
            <Input.TextArea
              placeholder='请输入'
              maxLength={200}
              showCount
              style={{ minHeight: '100px' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-03-18 13:58:08
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-21 15:06:32
 */

import { CaretUpFilled } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, Radio, Space } from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './onlinePrescription.module.css'
import { getPotionSupplierAsync } from './onlinePrescriptionSlice'

interface PrescriptionUploadModalProps {
  potionWay?:any
  flag?: any
  recipeId?: any
  treatmentId?: any
  visible: boolean
  onCancel: () => void
  onOk: (v?: any) => void
}
export const PrescriptionUploadModal = ({
  potionWay,
  flag,
  recipeId,
  treatmentId,
  visible,
  onCancel,
  onOk,
}: PrescriptionUploadModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const upload_DJValue: any = localStorage.getItem('upload_DJValue')

  const [list, setList] = useState<any>()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        potionWay:potionWay
      })
      dispatch(getPotionSupplierAsync())
        .then(unwrapResult)
        .then((v) => {
          setList(v)
        })
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      const data = upload_DJValue ? JSON.parse(upload_DJValue) : ''
      if (data) {
        form.setFieldsValue({
          supplierId: Number(data?.supplierId),
          specification: data?.specification,
          remark: data?.remark,
        })
      }
    } else {
      form.resetFields()
    }
  }, [visible])

  const firstColumnLayout = {
    labelCol: { flex: '6rem' },
    wrapperCol: { span: 20 },
  }

  return (
    <>
      <Modal
        title={'处方上传'}
        width={810}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        className={!flag? styles.modalMain :styles.modalMains }
        centered
        footer={null}
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          form={form}
          onFinish={(v) => {
            onOk && onOk(v)
            localStorage.setItem('upload_DJValue', JSON.stringify(v))
          }}
        >
          <div style={{ height: !flag ? '280px' : '100px', overflow: 'auto' }}>
            <Col span={24}>
              <Form.Item
                {...firstColumnLayout}
                name='supplierId'
                label='选择厂商'
                required
                rules={[{ required: true, message: '厂商不能为空' }]}
              >
                <Radio.Group>
                  {list?.map((v: any, index: any) => {
                    return (
                      <Radio
                        key={index}
                        value={v?.supplierId}
                        style={{ width: '200px', lineHeight: '32px' }}
                      >
                        {v?.name}
                      </Radio>
                    )
                  })}
                </Radio.Group>
              </Form.Item>
              {!flag ? (
                <>
                  <Form.Item
                    {...firstColumnLayout}
                    name='potionWay'
                    label='加工方式'
                  >
                    <Radio.Group>
                      <Radio
                        disabled
                        value={2}
                        style={{ width: '200px', lineHeight: '32px' }}
                      >
                        自煎
                      </Radio>
                      <Radio
                        disabled
                        value={1}
                        style={{ width: '200px', lineHeight: '32px' }}
                      >
                        代煎
                      </Radio>
                      <Radio
                        disabled
                        value={7}
                        style={{ width: '200px', lineHeight: '32px' }}
                      >
                        制丸
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name='specification'
                    label='包装规格'
                    {...firstColumnLayout}
                  >
                    <Input placeholder='请输入包装规格'></Input>
                  </Form.Item>
                  <Form.Item name='remark' label='备注' {...firstColumnLayout}>
                    <Input.TextArea
                      placeholder='请输入备注'
                      maxLength={300}
                      showCount
                    ></Input.TextArea>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
            </Col>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Space>
              <Button
                onClick={() => {
                  onCancel()
                  form.resetFields()
                }}
              >
                取消
              </Button>
              <Button type='primary' htmlType='submit'>
                确定
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  )
}

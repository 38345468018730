/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 14:10:35
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-17 09:59:57
 */
import {
  CloseOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Carousel,
  Col,
  Form,
  Input,
  Image,
  notification,
  Row,
  Upload,
  Select,
  Space,
  Checkbox,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { OssParams } from '../../models/ossparams'
import { getBase64, tempArr } from '../../utils/StringUtils'
import { getOssUrl } from '../DepartmentManagement/departmentManagementSlice'
import styles from './hospitalIntroduce.module.css'
import {
  getAdvertisingDetail,
  getAdvertisingSubmit,
  getHospitalIntroduceDetail,
  getHospitalIntroduceSubmit,
  getUrl,
} from './hospitalIntroduceSlice'

export const HospitalIntroduce = (): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [messageForm] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [imageUrl, setImageUrl] = useState<any>('') //附件，用于传给后端的oss路径参数

  const [imageUrls, setImageUrls] = useState<any>('') //附件，用于传给后端的oss路径参数

  const [imageUrlTwos, setImageUrlTwos] = useState<any>('') //附件，用于传给后端的oss路径参数

  const [imageUrlThrees, setImageUrlThrees] = useState<any>('') //附件，用于传给后端的oss路径参数

  const [indexs, setIndex] = useState<any>(0)

  const [data, setData] = useState<any>()

  const [visible, setVisible] = useState(false)

  const [lableList, setLableList] = useState<any>([])

  const [headImgList, setHeadImgList] = useState<any>([])

  const [fileListHead, setFileListHead] = useState<File[]>([]) //文件

  const [publicityImgList, setPublicityImgList] = useState<any>([])

  const [fileListPublicity, setFileListPublicity] = useState<File[]>([]) //文件

  const [hospitalImgList, setHospitalImgList] = useState<any>([])

  const [fileListhospital, setFileListHospital] = useState<File[]>([]) //文件

  const [tabKey, setTabKey] = useState('0')

  const [adverDetail, setAdverDetail] = useState<any>()

  const [checked, setChecked] = useState<boolean>(true)

  // useEffect(() => {
  //   console.log(indexs, 'indexs')
  //   if (indexs == 0) {
  //     setImageUrls(imageUrlTwos)
  //     //  setImageUrlTwos('')
  //   }else if(indexs == 1)
  //    setImageUrlTwos('')
  // }, [indexs,imageUrlTwos])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  )

  const rowProps = {
    gutter: 4,
  }

  const colProps = { span: 8 }

  const firstColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 16 },
  }

  const allColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 20 },
  }

  const tipColumnLayout = {
    labelCol: { flex: '20rem' },
    wrapperCol: { span: 20 },
  }
  const advertisingDetail = () => {
    dispatch(getAdvertisingDetail())
      .then(unwrapResult)
      .then((v) => {
        setAdverDetail(v?.records)
        console.log(v, 111111111111111)
      })
  }

  useEffect(() => {
    if (tabKey == '0') {
      refreshDetail()
    } else if (tabKey === '1') {
      advertisingDetail()
    }
  }, [tabKey])

  const refreshDetail = () => {
    dispatch(getHospitalIntroduceDetail())
      .then(unwrapResult)
      .then((v: any) => {
        const labelList = v.labelIds ? v.labelIds?.split(',') : []
        const headImg = v.hosHeaderFigure?.split('@$$@')?.map((v: any) => {
          return { imgBaseInfo: v }
        })
        const publicityImg = v.hosLogo?.split('@$$@')?.map((v: any) => {
          return { imgBaseInfo: v }
        })
        const hosImg = v.hosPictures?.split('@$$@')?.map((v: any) => {
          return { imgBaseInfo: v }
        })
        setLableList(labelList)
        setHeadImgList(headImg)
        setPublicityImgList(publicityImg)
        setHospitalImgList(hosImg)
        setData(v)
        form.setFieldsValue({
          ...v,
          hosHeaderFigure: headImg,
          hosLogo: publicityImg,
          hosPictures: hosImg,
          longitude: v?.longitude > 0 ? v?.longitude?.toFixed(5) : '',
          latitude: v?.latitude > 0 ? v?.latitude?.toFixed(5) : '',
          adviceTel: v?.adviceTel,
          introduction: v?.introduction,
        })
      })
  }

  useEffect(() => {
    if (adverDetail?.length && tabKey == '1') {
      messageForm.setFieldsValue({
        adverts: [...adverDetail],
      })
      setImageUrls(adverDetail?.[0]?.pic)
      setImageUrlTwos(adverDetail?.[1]?.pic)
      setImageUrlThrees(adverDetail?.[2]?.pic)
    } else {
      messageForm.setFieldsValue({
        adverts: [''],
      })
    }
  }, [tabKey, adverDetail])

  return (
    <>
      <TabBar
        style={{ margin: '10px 20px' }}
        onChange={(v) => {
          setTabKey(v)
        }}
      >
        <TabPane key='0' tab='医院介绍'>
          <div className={styles.container}>
            <div className={styles.main}>
              <div className={styles.left}>
                <Form
                  className={styles.form}
                  name='basic'
                  form={form}
                  labelCol={{
                    span: 3,
                  }}
                  wrapperCol={{
                    span: 21,
                  }}
                  onFinish={(values) => {
                    console.log(values, 'values')
                    const hosHeaderFigure = headImgList
                      ?.map((v: any) => {
                        return v?.imgBaseInfo
                      })
                      ?.join('@$$@')

                    const hosLogo = publicityImgList
                      ?.map((v: any) => {
                        return v?.imgBaseInfo
                      })
                      ?.join('@$$@')

                    const hosPictures = hospitalImgList
                      ?.map((v: any) => {
                        return v?.imgBaseInfo
                      })
                      ?.join('@$$@')

                    dispatch(
                      getHospitalIntroduceSubmit({
                        // ...values,
                        ...data,
                        hosHeaderFigure: hosHeaderFigure
                          ? hosHeaderFigure
                          : data?.hosHeaderFigure,
                        hosLogo: hosLogo,
                        hosPictures: hosPictures,
                        introduction: values?.introduction
                          ? values?.introduction
                          : data?.appointmentBaseSettingsVO?.introduction,
                        longitude: values?.longitude
                          ? values?.longitude
                          : data?.tenantDetail?.longitude,
                        latitude: values?.latitude
                          ? values?.latitude
                          : data?.tenantDetail?.latitude,
                        adviceTel: values?.adviceTel
                          ? values?.adviceTel
                          : data?.adviceTel,
                        labelIds: lableList?.join(','),
                        address: values?.address,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        refreshDetail()
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                      })
                  }}
                >
                  {/* <Row {...rowProps}>
                    <Col {...colProps} span={24}>
                      <Form.Item
                        label='首页头图'
                        name='hosHeaderFigure'
                        {...allColumnLayout}
                        rules={[{ required: true, message: '请上传首页头图' }]}
                      >
                        <Row className={styles.upload}>
                          {headImgList?.length < 5 && (
                            <Upload
                              beforeUpload={(file) => {
                                if (file.size / 1024 < 500) {
                                  return true
                                } else {
                                  notification.info({
                                    message: '图片大于500kb,请重新选择!',
                                  })
                                  return false
                                }
                              }}
                              maxCount={5}
                              showUploadList={false}
                              customRequest={async ({ file, onSuccess }) => {
                                if (file instanceof File) {
                                  const formData = new FormData()
                                  // 向 FormData 中添加新的属性值 append()方法
                                  formData.append('file', file)
                                  await dispatch(getOssUrl(formData))
                                    .then(unwrapResult)
                                    .then((v) => {
                                      onSuccess?.(
                                        null,
                                        (null as unknown) as XMLHttpRequest
                                      )
                                      setImageUrl(v)
                                      setHeadImgList([
                                        //保存预览图片
                                        ...headImgList,
                                        {
                                          imgBaseInfo: v,
                                        },
                                      ])
                                      console.log(v, 'blobInfo')
                                    })
                                    .catch(() => {
                                      return
                                    })

                                  setFileListHead([
                                    //保存 file文件
                                    ...fileListHead,
                                    file,
                                  ])
                                }
                              }}
                            >
                              <div className={styles.uploadButton}>
                                上传图片
                              </div>
                            </Upload>
                          )}
                          <div className={styles.uploadText}>
                            用于小程序“首页“页面的头图，支持最多上传5张，尺寸：1125*600,支持.jpg、.png、.jpeg等图片格式
                          </div>
                        </Row>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {headImgList?.length != 0 &&
                            [...headImgList]?.map((v: any, index: number) => {
                              return (
                                <div key={index} className={styles.imgWrap}>
                                  <span
                                    className={styles.imgClose}
                                    onClick={(i) => {
                                      console.log(i, v, '1111111111111')
                                      setHeadImgList(
                                        headImgList.filter((i: any) => i != v)
                                      )
                                      setFileListHead(
                                        tempArr(fileListHead, 'uid').filter(
                                          (i: any) => i != v
                                        )
                                      )
                                      notification.success({
                                        message: `删除成功`,
                                      })
                                    }}
                                  >
                                    <CloseOutlined />
                                  </span>
                                  {v?.imgBaseInfo ? (
                                    <img
                                      className={styles.img}
                                      onClick={() => {
                                        setVisible(true)
                                      }}
                                      src={v?.imgBaseInfo}
                                    />
                                  ) : (
                                    <img className={styles.img} src={v} />
                                  )}
                                </div>
                              )
                            })}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Row {...rowProps}>
                    <Col {...colProps} span={24}>
                      <Form.Item
                        label='宣传图'
                        name='hosLogo'
                        {...allColumnLayout}
                        rules={[{ required: true, message: '请上传宣传图片' }]}
                      >
                        <Row className={styles.upload}>
                          {publicityImgList?.length < 1 && (
                            <Upload
                              beforeUpload={(file) => {
                                if (file.size / 1024 < 500) {
                                  return true
                                } else {
                                  notification.info({
                                    message: '图片大于500kb,请重新选择!',
                                  })
                                  return false
                                }
                              }}
                              maxCount={10}
                              showUploadList={false}
                              customRequest={async ({ file }) => {
                                const formData = new FormData()
                                // 向 FormData 中添加新的属性值 append()方法
                                formData.append('file', file)
                                if (file instanceof File) {
                                  dispatch(getOssUrl(formData))
                                    .then(unwrapResult)
                                    .then((v) => {
                                      setImageUrl(v)
                                      setPublicityImgList([
                                        //保存预览图片
                                        ...publicityImgList,
                                        {
                                          imgBaseInfo: v,
                                        },
                                      ])
                                      console.log(v, 'blobInfo')
                                    })
                                    .catch(() => {
                                      return
                                    })

                                  setFileListPublicity([
                                    //保存 file文件
                                    ...fileListPublicity,
                                    file,
                                  ])
                                }
                              }}
                            >
                              <div className={styles.uploadButton}>
                                上传图片
                              </div>
                            </Upload>
                          )}
                          <div className={styles.uploadText}>
                            用于“我的“页面，支持上传1张，尺寸：1065*300,支持.jpg、.png、.jpeg等图片格式
                          </div>
                        </Row>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {publicityImgList?.length != 0 &&
                            [...publicityImgList]?.map(
                              (v: any, index: number) => {
                                return (
                                  <div key={index} className={styles.imgWrap}>
                                    <span
                                      className={styles.imgClose}
                                      onClick={(i) => {
                                        console.log(i, v, '1111111111111')
                                        setPublicityImgList(
                                          publicityImgList.filter(
                                            (i: any) => i != v
                                          )
                                        )
                                        setFileListPublicity(
                                          tempArr(
                                            fileListPublicity,
                                            'uid'
                                          ).filter((i: any) => i != v)
                                        )
                                        notification.success({
                                          message: `删除成功`,
                                        })
                                      }}
                                    >
                                      <CloseOutlined />
                                    </span>
                                    {v?.imgBaseInfo ? (
                                      <img
                                        className={styles.img}
                                        onClick={() => {
                                          setVisible(true)
                                        }}
                                        src={v?.imgBaseInfo || v?.info}
                                      />
                                    ) : (
                                      <img
                                        className={styles.img}
                                        src={v?.info}
                                      />
                                    )}
                                  </div>
                                )
                              }
                            )}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col {...colProps} span={24}>
                      <Form.Item
                        label='医院图片'
                        name='hosPictures'
                        {...allColumnLayout}
                        rules={[{ required: true, message: '请上传医院图片' }]}
                      >
                        <Row className={styles.upload}>
                          {hospitalImgList?.length < 5 && (
                            <Upload
                              beforeUpload={(file) => {
                                if (file.size / 1024 < 500) {
                                  return true
                                } else {
                                  notification.info({
                                    message: '图片大于500kb,请重新选择!',
                                  })
                                  return false
                                }
                              }}
                              maxCount={10}
                              showUploadList={false}
                              customRequest={async ({ file }) => {
                                const formData = new FormData()
                                // 向 FormData 中添加新的属性值 append()方法
                                formData.append('file', file)
                                if (file instanceof File) {
                                  dispatch(getOssUrl(formData))
                                    .then(unwrapResult)
                                    .then((v) => {
                                      setImageUrl(v)
                                      setHospitalImgList([
                                        //保存预览图片
                                        ...hospitalImgList,
                                        {
                                          imgBaseInfo: v,
                                        },
                                      ])
                                      console.log(v, 'blobInfo')
                                    })
                                    .catch(() => {
                                      return
                                    })

                                  setFileListHospital([
                                    //保存 file文件
                                    ...fileListhospital,
                                    file,
                                  ])
                                }
                              }}
                            >
                              <div className={styles.uploadButton}>
                                上传图片
                              </div>
                            </Upload>
                          )}
                          <div className={styles.uploadText}>
                            用于医院介绍页面的头图，支持最多上传5张，尺寸：1125*600,支持.jpg、.png、.jpeg等图片格式{' '}
                          </div>
                        </Row>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {hospitalImgList?.length != 0 &&
                            [...hospitalImgList]?.map(
                              (v: any, index: number) => {
                                return (
                                  <div key={index} className={styles.imgWrap}>
                                    <span
                                      className={styles.imgClose}
                                      onClick={(i) => {
                                        console.log(i, v, '1111111111111')
                                        setHospitalImgList(
                                          hospitalImgList.filter(
                                            (i: any) => i != v
                                          )
                                        )
                                        setFileListHospital(
                                          tempArr(
                                            fileListhospital,
                                            'uid'
                                          ).filter((i: any) => i != v)
                                        )
                                        notification.success({
                                          message: `删除成功`,
                                        })
                                      }}
                                    >
                                      <CloseOutlined />
                                    </span>
                                    {v?.imgBaseInfo ? (
                                      <img
                                        className={styles.img}
                                        onClick={() => {
                                          setVisible(true)
                                        }}
                                        src={v?.imgBaseInfo || v?.info}
                                      />
                                    ) : (
                                      <img
                                        className={styles.img}
                                        src={v?.info}
                                      />
                                    )}
                                  </div>
                                )
                              }
                            )}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '14px',
                      justifyContent: 'flex-end',
                      margin: ' -22px 0 26px 20px',
                      marginLeft: '100px',
                      color: '#666',
                    }}
                  ></Col>
                  <Row {...rowProps}>
                    <Col {...colProps} span={12}>
                      <Form.Item
                        {...firstColumnLayout}
                        label='经度'
                        required
                        style={{ height: '30px' }}
                        name='longitude'
                        rules={[
                          { required: true, message: '经度不能为空' },
                          {
                            pattern: /^[-+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d{1}\.\d{1,5}|180\.0{1,5})$/,
                            message: '请输入0-180的数字',
                          },
                        ]}
                      >
                        <Input placeholder='请输入0-180的数字' />
                      </Form.Item>
                    </Col>
                    <Col {...colProps} span={12}>
                      <Form.Item
                        {...firstColumnLayout}
                        name='latitude'
                        label='纬度'
                        rules={[
                          { required: true, message: '纬度不能为空' },
                          {
                            pattern: /^[-+]?([0-8]?\d{1}\.\d{1,5}|90\.0{1,5})$/,
                            message: '请输入0-90的数字',
                          },
                        ]}
                      >
                        <Input placeholder='请输入0-90的数字' />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col {...colProps} span={24}>
                      <Form.Item
                        name='label'
                        label='标签'
                        {...firstColumnLayout}
                      >
                        <div style={{ display: 'flex' }}>
                          {lableList?.length != 0 && (
                            <Col>
                              {lableList?.map((d: any) => (
                                <div key={d} className={styles.labelColor}>
                                  {d}
                                  <div className={styles.closeIcon}>
                                    <CloseOutlined
                                      style={{
                                        color: '#fff',
                                        cursor: 'pointer',
                                        fontSize: '8px',
                                      }}
                                      onClick={() => {
                                        setLableList(
                                          lableList.filter((i: any) => i !== d)
                                        )
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </Col>
                          )}
                          {lableList?.length < 5 && (
                            <Input
                              placeholder='输入标签'
                              maxLength={20}
                              style={{ width: '150px', height: '32px' }}
                              onPressEnter={(v: any) => {
                                if (
                                  !lableList?.find(
                                    (d: any) => d == v.target.value
                                  )
                                ) {
                                  if (v.target.value) {
                                    setLableList(
                                      lableList?.concat(v.target.value)
                                    )
                                  } else {
                                    setLableList(lableList)
                                  }
                                  form.resetFields(['label'])
                                }
                              }}
                              onBlur={(v: any) => {
                                if (
                                  !lableList?.find(
                                    (d: any) => d == v.target.value
                                  )
                                ) {
                                  if (v.target.value) {
                                    setLableList(
                                      lableList?.concat(v.target.value)
                                    )
                                  } else {
                                    setLableList(lableList)
                                  }
                                  form.resetFields(['label'])
                                  // form.setFieldsValue({
                                  //   label: '',
                                  // })
                                }
                              }}
                            />
                          )}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row {...rowProps}>
                    <Col {...colProps} span={12}>
                      <Form.Item
                        {...firstColumnLayout}
                        name='adviceTel'
                        label='医院电话'
                        required
                        rules={[
                          { required: true, message: '机构电话不能为空' },
                          {
                            pattern: new RegExp(
                              /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                            ),
                            message: '请输入正确号码',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col {...colProps} span={24}>
                      <Form.Item
                        {...allColumnLayout}
                        name='address'
                        label='医院地址'
                        required
                        rules={[
                          { required: true, message: '医院地址不能为空' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col {...colProps} span={24}>
                      <Form.Item
                        {...allColumnLayout}
                        name='introduction'
                        label='医院简介'
                        required
                        rules={[
                          { required: true, message: '诊所简介不能为空' },
                        ]}
                      >
                        <TextArea
                          showCount
                          maxLength={1000}
                          rows={6}
                          placeholder='多行输入'
                          onChange={(v) => {
                            console.log(
                              v.target.value,
                              v.target.value?.split('\n'),
                              'v.target.value'
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    type='primary'
                    // htmlType='submit'
                    style={{
                      float: 'left',
                      marginLeft: '115px',
                    }}
                    onClick={() => {
                      form.submit()
                    }}
                  >
                    保存
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane key='1' tab='广告设置'>
          <div className={styles.containers}>
            <Form
              name='dynamic_form_nest_item'
              onFinish={(v) => {
                const submitParam = v?.adverts?.map((i: any, index: any) => {
                  console.log(index, 'index')
                  return {
                    // ...i,
                    isShow: i?.isShow ? i?.isShow : false,
                    pic: index == 0 ? imageUrls : index == 1 ? imageUrlTwos :imageUrlThrees,
                    url: i?.url,
                  }
                })

                dispatch(getAdvertisingSubmit(submitParam))
                  .then(unwrapResult)
                  .then((v) => {
                    notification.success({
                      message: '操作成功',
                    })
                    advertisingDetail()
                  })
                console.log(v, '6666')
              }}
              autoComplete='off'
              form={messageForm}
            >
              <Form.List name='adverts'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field: any, index: any) => (
                      <Space
                        key={field.key}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 8,
                        }}
                        align='baseline'
                      >
                        <Row>
                          {fields.length === index + 1 ? (
                            <PlusCircleOutlined
                              style={{
                                marginRight: 8,
                                fontSize: '20px',
                              }}
                              onClick={() => {
                                if (index == 2) {
                                  notification.info({
                                    message: '最多添加三个广告位',
                                    duration: 3,
                                  })
                                } else {
                                  add()
                                }
                              }}
                            />
                          ) : null}
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              style={{
                                fontSize: '20px',
                              }}
                              onClick={() => {
                                console.log(field.key, index, 'field')
                                setIndex(index)
                                remove(field.name)
                                if (index == 0) {
                                  setImageUrls(imageUrlTwos)
                                  setImageUrlTwos('')
                                  setImageUrlThrees('')
                                }
                              }}
                            />
                          ) : null}
                        </Row>
                        <Row
                          style={{
                            marginLeft: 30,
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        >
                        { index  == 2 ? '互联网首页广告位':'首页广告位' +( index + 1)}
                        </Row>
                        <Row
                          style={{
                            marginLeft: 40,
                          }}
                        >
                          <Form.Item
                            label='广告图片'
                            name={[field.name, 'pic']}
                            rules={[
                              { required: true, message: '请上传图片' },
                            ]}
                          >
                            <Row>
                              <Col>
                                <Upload
                                  beforeUpload={(file) => {
                                    if (file.size / 1024 < 100) {
                                      return true
                                    } else {
                                      notification.info({
                                        message: '图片大于100kb,请重新选择!',
                                      })
                                      return false
                                    }
                                  }}
                                  accept={'.png,.jpg,.jpeg,bmp'}
                                  name='avatar'
                                  listType='picture-card'
                                  className='avatar-uploader'
                                  showUploadList={false}
                                  customRequest={async ({ file }) => {
                                    if (file) {
                                      const formData = new FormData()
                                      // 向 FormData 中添加新的属性值 append()方法
                                      formData.append('file', file)
                                      dispatch(getOssUrl(formData))
                                        .then(unwrapResult)
                                        .then((v) => {
                                          if (index == 0) {
                                            setImageUrls(v)
                                          } else if (index == 1) {
                                            setImageUrlTwos(v)
                                          }else if (index == 2) {
                                            setImageUrlThrees(v)
                                          }
                                          console.log(v, 'blobInfo')
                                        })
                                        .catch(() => {
                                          return
                                        })
                                    }
                                  }}
                                >
                                  {imageUrls && index == 0 ? (
                                    <img
                                      src={imageUrls}
                                      alt='avatar'
                                      style={{
                                        width: '100%',
                                        maxHeight: '102px',
                                      }}
                                    />
                                  ) : (
                                    index == 0 && uploadButton
                                  )}
                                  {imageUrlTwos && index == 1 ? (
                                    <img
                                      src={imageUrlTwos}
                                      alt='avatar'
                                      style={{
                                        width: '100%',
                                        maxHeight: '102px',
                                      }}
                                    />
                                  ) : (
                                    index == 1 && uploadButton
                                  )}
                                  {imageUrlThrees && index == 2 ? (
                                    <img
                                      src={imageUrlThrees}
                                      alt='avatar'
                                      style={{
                                        width: '100%',
                                        maxHeight: '102px',
                                      }}
                                    />
                                  ) : (
                                    index == 2 && uploadButton
                                  )}
                                </Upload>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  fontSize: '14px',
                                  color: '#cbcacb ',
                                  margin: '0  0 4px 10px',
                                }}
                              >
                                支持.jpg、.png、.jpeg等图片格式
                                {/* <br /> 科室头像为空时用系统默认头像 */}
                              </Col>
                            </Row>
                          </Form.Item>
                        </Row>
                        <Row
                          style={{
                            marginLeft: 40,
                          }}
                        >
                          <Form.Item
                            name={[field.name, 'url']}
                            style={{
                              marginRight: 20,
                            }}
                            label='链接地址'
                          >
                            <Input
                              placeholder='请输入链接地址'
                              style={{
                                width: '400px',
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            valuePropName='checked'
                            label='是否展示'
                            name={[field.name, 'isShow']}
                          >
                            <Checkbox
                              value={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            ></Checkbox>
                          </Form.Item>
                        </Row>
                      </Space>
                    ))}
                    {/* <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item> */}
                  </>
                )}
              </Form.List>
              <Form.Item
                style={{
                  width: '614px',
                  textAlign: 'right',
                  margin: '20px 0 0 0 ',
                }}
              >
                <Button type='primary' htmlType='submit'>
                  保存
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
      </TabBar>
    </>
  )
}
